import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  MemoryRouter,
} from "react-router-dom";
import OverlayLoader from "./components/OverlayLoader";
import PaymentVoucherIndex from "./containers/indeces/inventoryMaster/PaymentVoucherIndex";
import PaymentJournal from "./containers/indeces/inventoryMaster/PaymentJournal";
import PaymentVoucherPdf from "./pages/forms/inventoryMaster/PaymentVoucherPdf";
import PaymentJournalVoucherPdf from "./pages/forms/inventoryMaster/PaymentJournalVoucherPdf";
import PaymentContraVoucherPdf from "./components/ContraVoucherPdf";
import PayreportPdf from "./components/payreportPdf.jsx";
import GrnPdf from "./components/GrnPdf.jsx";
import Chart from "chart.js/auto";
import JournalVoucherPdf from "./pages/forms/journals/JournalVoucherPdf";
import JournalVoucherEdit from "./pages/forms/journals/JournalVoucherEdit";
import ItemCreation from "./pages/forms/inventoryMaster/ItemCreation.jsx";
import RestrictWindowMaster from "./pages/masters/RestrictWindow.jsx";
import PaysliplockCreate from "./containers/indeces/restrictwindowMaster/paysliplock/createpaysliploack.jsx";
import PaysliplockEdit from "./containers/indeces/restrictwindowMaster/paysliplock/editpaysliploack.jsx";
import StudentMarks from "./pages/forms/studentMaster/StudentMarks.jsx";
import StudentMarksIndex from "./pages/forms/studentMaster/StudentMarksIndex.jsx";
import AdmissionPage from "./pages/forms/chartsDashboard/admission/index.jsx";
import ItemMaster from "./pages/masters/ItemMaster.jsx";
import AttendServiceMaster from "./pages/forms/myRequest/AttendServiceMaster.jsx";
import AttendServiceHistory from "./pages/forms/myRequest/AttendServiceHistory.jsx";
import Grnpdfone from "./components/Grnpdfone.jsx";
import StoreIndentRequests from "./containers/indeces/inventoryMaster/StoreIndentRequests.jsx";
import StudentAssessmentPdf from "./pages/forms/studentMaster/StudentAssessmentPdf.jsx";
import AttendRequestMaster from "./pages/forms/myRequest/RequestMasterReport.jsx";
import StudentDueByProgramme from "./pages/forms/studentMaster/StudentDueByProgramme.jsx";
import ClosingstockReport from "./components/ClosingstockReport.jsx";
import ServiceRequestGraph from "./pages/forms/myRequest/graphView/ServiceRequestGraph.jsx";
import DueFollowHistory from "./pages/forms/studentMaster/DueFollowHistory.jsx";
import ChangeOfCourse from "./pages/forms/studentMaster/ChangeOfCourse.jsx";
import ChangeOfCourseIndex from "./containers/indeces/studentMaster/ChangeOfCourseIndex.jsx";
import ApproveChangeofcourse from "./pages/forms/studentMaster/ApproveChangeofcourse.jsx";
import FeeTransfer from "./pages/forms/studentMaster/FeeTransfer.jsx";
import StudentLibraryDetails from "./pages/forms/studentMaster/StudentLibraryDetails.jsx";
import StockSubRegister from "./containers/indeces/inventoryMaster/StockSubRegister.jsx";
import ChangeOfCourseAttachment from "./pages/forms/inventoryMaster/ChangeOfCourseAttachment.jsx";
import ProctorStudentMaster from "./pages/masters/ProctorStudentMaster.jsx";
import ProctorStudentsMeeting from "./pages/forms/mentorMaster/ProctorStudentsMeeting.jsx";
import CustomTemplate from "./pages/forms/documentrepo/custom-template.jsx";
import RefreshmentMaster from "./pages/forms/cateringMaster/refreshmentReport/RefreshmentMaster.jsx";
import CreateRefreshmentRequest from "./pages/forms/cateringMaster/refreshmentApprover/CreateRefreshmentRequest.jsx";
import DeatilsByLeaveType from "./containers/indeces/leaveMaster/DetailsByLeaveType.jsx";
import SalaryIncrementInitIndex from "./pages/indeces/SalaryIncrementInitiation.jsx";
import SalaryBudgetCreate from "./pages/forms/salaryIncrement/SalaryBudgetCreate.jsx";
import BudgetIncrementIndex from "./pages/forms/salaryIncrement/BudgetIncrementIndex.jsx";
import StudentFedbackWindow from "./containers/indeces/studentFeedbackMaster/StudentFeedbackWindow.jsx";
import StudentFeedbackFreezeCreate from "./containers/indeces/studentFeedbackMaster/StudentFeedbackFreezeCreate.jsx";
import StudentFeedbackWindowUpdate from "./containers/indeces/studentFeedbackMaster/StudentFeedbackWindowUpdate.jsx";
import StudentFeddbackFreezeUpdate from "./containers/indeces/studentFeedbackMaster/StudentFeddbackFreezeUpdate.jsx";
import AllowStudentFeedback from "./containers/indeces/studentFeedbackMaster/AllowStudentFeedback.jsx";
import SubmitFeedbackSelect from "./containers/indeces/studentFeedbackMaster/SubmitFeedbackSelect.jsx";
import SubmitFeedback from "./containers/indeces/studentFeedbackMaster/SubmitFeedback.jsx";
import EmployeeFeedbackIndex from "./containers/indeces/studentFeedbackMaster/EmployeeFeedbackIndex.jsx";
import EventForm from "./containers/indeces/dailyPlanner/eventCreation.jsx";
import EmployeeFeedbackReport from "./containers/indeces/studentFeedbackMaster/EmployeeFeedbackReport.jsx";
import TaskList from "./containers/indeces/dailyPlanner/taskList.jsx";
import CandidateContractQualified from "./pages/forms/candidateWalkin/CandidateContractQualified.jsx";

const BudgetCreateCsv = lazy(() =>
  import("./pages/indeces/BudgetCreateCsv.jsx")
);

const IncrementIndex = lazy(() => import("./pages/indeces/IncrementIndex.jsx"));

const IncrementFinalizedList = lazy(() =>
  import("./pages/indeces/IncrementFinalizedList.jsx")
);

const RouteList = lazy(() => import("./RoutesList.jsx"));
const PaySlip = lazy(() => import("./components/payslip.jsx"));
const PayReport = lazy(() => import("./components/payreport.jsx"));
const StockRegister = lazy(() => import("./components/StockRegister.jsx"));
const StockIssue = lazy(() => import("./components/StockIssues.jsx"));
const StockIssueIndex = lazy(() => import("./components/StockIssueIndex.jsx"));

const StudentIdCard = lazy(() =>
  import("./pages/forms/idcardSection/StudentIdCard")
);
const EmployeeIdCard = lazy(() =>
  import("./pages/forms/idcardSection/EmployeeIdCard")
);
const DocumentsRepo = lazy(() => import("./pages/forms/documentrepo"));
const DocumentList = lazy(() =>
  import("./pages/forms/documentrepo/documentsList")
);
const ChartsDashboard = lazy(() => import("./pages/forms/chartsDashboard"));
const FinancePage = lazy(() =>
  import("./pages/forms/chartsDashboard/finance/index")
);
const GRNPage = lazy(() => import("./components/GRNView.jsx"));
const GRNIndex = lazy(() => import("./components/GRNIndex.jsx"));
const HRMPage = lazy(() => import("./pages/forms/chartsDashboard/hrm/index"));
const NavigationLayout = lazy(() => import("./layouts/NavigationLayout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const FormExample = lazy(() => import("./containers/examples/FormExample"));
const Profile = lazy(() => import("./pages/Profile"));

// Master pages
const InstituteMaster = lazy(() => import("./pages/masters/InstituteMaster"));
const NavigationMaster = lazy(() => import("./pages/masters/NavigationMaster"));
const AcademicMaster = lazy(() => import("./pages/masters/AcademicMaster"));
const AdmissionMaster = lazy(() => import("./pages/masters/AdmissionMaster"));
const DesignationMaster = lazy(() =>
  import("./pages/masters/DesignationMaster")
);
const ShiftMaster = lazy(() => import("./pages/masters/ShiftMaster"));
const AccountMaster = lazy(() => import("./pages/masters/AccountMaster"));
const AcademicCalendars = lazy(() =>
  import("./pages/masters/AcademicCalendars")
);
const SalaryMaster = lazy(() => import("./pages/masters/SalaryMaster"));
const InventoryMaster = lazy(() => import("./pages/masters/InventoryMaster"));
const PaymentMaster = lazy(() => import("./pages/masters/PaymentMaster"));
const DraftVouchers = lazy(() => import("./pages/masters/DraftVouchers"));
const TranscriptMaster = lazy(() => import("./pages/masters/TranscriptMaster"));
const StudentDueDetailsMaster = lazy(() =>
  import("./pages/forms/studentMaster/StudentDueDetailsMaster")
);
const StudentMarksMaster = lazy(() =>
  import("./pages/forms/studentMaster/StudentMarksMasterIndex")
);
const EmployeeBioMetricData = lazy(() =>
  import("./components/EmployeeBiometicData")
);

const LibraryFinePayment = lazy(() =>
  import("./pages/forms/studentMaster/LibraryFinePayment.jsx")
);

const InfrastructureMaster = lazy(() =>
  import("./pages/masters/InfrastructureMaster")
);
const FeetemplateMaster = lazy(() =>
  import("./pages/masters/FeetemplateMaster")
);
const CategoryTypeMaster = lazy(() =>
  import("./pages/masters/CategoryTypeMaster")
);
const CourseMaster = lazy(() => import("./pages/masters/CourseMaster"));
const LeaveMaster = lazy(() => import("./pages/masters/LeaveMaster"));
const HolidayCalenderMaster = lazy(() =>
  import("./pages/masters/HolidayCalenderMaster")
);

const HostelMaster = lazy(() => import("./pages/masters/HostelMaster"));
const MentorMaster = lazy(() => import("./pages/masters/MentorMaster"));
const ReportMaster = lazy(() =>
  import("./pages/masters/StudentReportingMaster")
);

const HostelFeeMaster = lazy(() => import("./pages/masters/HostelFeeMaster"));
const SectionMaster = lazy(() => import("./pages/masters/SectionMaster"));
const StudentFeedbackMaster = lazy(() =>
  import("./pages/masters/StudentFeedbackMaster")
);
const TimeTableMaster = lazy(() => import("./pages/masters/TimeTableMaster"));
const StudentDetailsMaster = lazy(() =>
  import("./pages/masters/StudentDetailsMaster")
);
const StudentTranscriptMaster = lazy(() =>
  import("./pages/masters/StudentTranscriptMaster")
);
const StudentDetailsPdf = lazy(() => import("./components/StudentDetailsPdf"));
const BankMaster = lazy(() => import("./pages/masters/BankMaster"));
const StudentIntakeMaster = lazy(() =>
  import("./pages/masters/StudentIntakeMaster")
);
const ExitFormMaster = lazy(() => import("./pages/masters/ExitFormMaster"));
const EventMaster = lazy(() => import("./pages/masters/EventMaster"));
const AcademicSectionMaster = lazy(() =>
  import("./pages/masters/AcademicSectionMaster")
);
const CourseSubjectiveMaster = lazy(() =>
  import("./pages/masters/CourseSubjectiveMaster")
);
const TransportMaster = lazy(() => import("./pages/masters/TransportMaster"));

const SessionMaster = lazy(() => import("./pages/masters/SessionMaster.jsx"));

// Institute master forms
const SchoolForm = lazy(() =>
  import("./pages/forms/instituteMaster/SchoolForm")
);
const OrganizationForm = lazy(() =>
  import("./pages/forms/instituteMaster/OrganizationForm")
);
const JobtypeForm = lazy(() =>
  import("./pages/forms/instituteMaster/JobtypeForm")
);
const EmptypeForm = lazy(() =>
  import("./pages/forms/instituteMaster/EmptypeForm")
);
const GraduationForm = lazy(() =>
  import("./pages/forms/instituteMaster/GraduationForm")
);
const SchoolVisionForm = lazy(() =>
  import("./pages/forms/instituteMaster/SchoolVisionForm")
);

// Navigation master forms
const ModuleForm = lazy(() =>
  import("./pages/forms/navigationMaster/ModuleForm")
);
const MenuForm = lazy(() => import("./pages/forms/navigationMaster/MenuForm"));
const SubmenuForm = lazy(() =>
  import("./pages/forms/navigationMaster/SubmenuForm")
);
const RoleForm = lazy(() => import("./pages/forms/navigationMaster/RoleForm"));

const ServiceMaster = lazy(() =>
  import("./pages/forms/myRequest/ServiceMaster")
);
const ServiceAssignmentForm = lazy(() =>
  import("./pages/forms/myRequest/ServiceAssignmentForm")
);
const ServiceTypeForm = lazy(() =>
  import("./pages/forms/myRequest/ServiceTypeForm")
);

// Academic master forms
const DepartmentForm = lazy(() =>
  import("./pages/forms/academicMaster/DepartmentForm")
);
const DepartmentAssignmentForm = lazy(() =>
  import("./pages/forms/academicMaster/DepartmentAssignmentForm")
);
const ProgramForm = lazy(() =>
  import("./pages/forms/academicMaster/ProgramForm")
);
const ProgramAssignmentForm = lazy(() =>
  import("./pages/forms/academicMaster/ProgramAssignmentForm")
);
const ProgramSpecializationForm = lazy(() =>
  import("./pages/forms/academicMaster/ProgramSpecializationForm")
);
const InternalCreationForm = lazy(() =>
  import("./pages/forms/academicMaster/InternalCreationForm")
);
const SessionAssignmentForm = lazy(() =>
  import("./pages/forms/academicMaster/SessionAssignmentForm")
);
const SessionAssignmentIndex = lazy(() =>
  import("./containers/indeces/academicMaster/SessionAssignmentIndex")
);
const InternalTimetablePdf = lazy(() =>
  import("./pages/forms/academicMaster/InternalTimetablePdf")
);

const InternalStudentsPdf = lazy(() =>
  import("./pages/forms/academicMaster/InternalStudentsPdf.jsx")
);
const SessionMarksEntryForm = lazy(() =>
  import("./pages/forms/academicMaster/SessionMarksEntryForm")
);
const SessionMarksEntry = lazy(() =>
  import("./pages/forms/academicMaster/SessionMarksEntry")
);
const VisionMissionForm = lazy(() =>
  import("./pages/forms/academicMaster/VisionMissionForm")
);
const StudentOnlineClass = lazy(() =>
  import("./pages/forms/academicMaster/StudentOnlineClass")
);
const InternalAssignmentForm = lazy(() =>
  import("./pages/forms/academicMaster/InternalAssignmentForm")
);
const InternalAssignmentIndex = lazy(() =>
  import("./containers/indeces/academicMaster/InternalAssignmentIndex")
);
const InternalRoomAssignment = lazy(() =>
  import("./pages/forms/academicMaster/InternalRoomAssignment")
);
// Admission master forms
const AdmCategoryForm = lazy(() =>
  import("./pages/forms/admissionMaster/AdmCategoryForm")
);
const AdmSubCategoryForm = lazy(() =>
  import("./pages/forms/admissionMaster/AdmSubcategoryForm")
);
const BoardForm = lazy(() => import("./pages/forms/admissionMaster/BoardForm"));
const CurrencytypeForm = lazy(() =>
  import("./pages/forms/admissionMaster/CurrencyForm")
);
const ProgramtypeForm = lazy(() =>
  import("./pages/forms/admissionMaster/ProgramtypeForm")
);

//Service Request
const ServiceRequestIndex = lazy(() =>
  import("./pages/forms/myRequest/CreateServiceRequestIndex")
);
const CreateServiceReqForm = lazy(() =>
  import("./pages/forms/myRequest/CreateServiceRequest")
);
const AttendServiceRequest = lazy(() =>
  import("./pages/forms/myRequest/AttendServiceRequest")
);
const AttendServiceRendorIndex = lazy(() =>
  import("./pages/forms/myRequest/AttendServiceRequestRendorIndex")
);

// User Creation
const UserForm = lazy(() => import("./pages/forms/UserForm"));
const UserIndex = lazy(() => import("./pages/indeces/UserIndex"));

// Job Portal
const JobPortalIndex = lazy(() => import("./pages/indeces/JobPortalIndex"));
const HodCommentsIndex = lazy(() => import("./pages/indeces/HodCommentsIndex"));
const CandidateAttachmentView = lazy(() =>
  import("./pages/forms/jobPortal/CandidateAttachmentView")
);
const InterView = lazy(() => import("./pages/forms/jobPortal/InterView"));
const ResultForm = lazy(() => import("./pages/forms/jobPortal/ResultForm"));
const SalaryBreakupForm = lazy(() =>
  import("./pages/forms/jobPortal/SalaryBreakupForm")
);
const SalaryBreakupPrint = lazy(() =>
  import("./pages/forms/jobPortal/SalaryBreakupPrint")
);
const OfferLetterPrint = lazy(() =>
  import("./pages/forms/jobPortal/OfferLetterPrint")
);
const OfferForm = lazy(() => import("./pages/forms/jobPortal/OfferForm"));
const RecruitmentForm = lazy(() =>
  import("./pages/forms/jobPortal/RecruitmentForm")
);
const EmployeeIndex = lazy(() => import("./pages/indeces/EmployeeIndex"));

const EmployeeSalaryApprovalIndex = lazy(() =>
  import("./pages/indeces/EmployeeSalaryApproverIndex.jsx")
);

const EmployeeSalaryApprove = lazy(() =>
  import("./pages/indeces/EmployeeSalaryApprove.jsx")
);

const EmployeeLeaveDetails = lazy(() =>
  import("./components/EmployeeLeaveDetails.jsx")
);

const EmployeeCalendar = lazy(() => import("./components/employeeCalendar"));

const CreatedStock = lazy(() => import("./components/CreatedStock.jsx"));

const CreatedGRN = lazy(() => import("./components/CreatedGRN.jsx"));

const JournalIndex = lazy(() => import("./pages/indeces/JournalIndex"));
const JournalVoucherNew = lazy(() =>
  import("./pages/forms/journals/JournalVoucherNew")
);

const ContraIndex = lazy(() => import("./components/ContraIndex"));

const CreateContra = lazy(() => import("./components/createContra"));

const EmployeeDetailsView = lazy(() =>
  import("./components/EmployeeDetailsView")
);

const MyProfile = lazy(() => import("./components/MyProfile"));

const EmployeeContractPdf = lazy(() =>
  import("./components/EmployeeContractPdf")
);
const EmployeeDeclarationPdf = lazy(() =>
  import("./components/EmployeeDeclarationPdf")
);
const OfferAccepted = lazy(() =>
  import("./pages/forms/jobPortal/OfferAccepted")
);
const EmployeeUpdateForm = lazy(() =>
  import("./pages/forms/jobPortal/EmployeeUpdateForm")
);

const RecruitmentUpdateForm = lazy(() =>
  import("./pages/forms/jobPortal/RecruitmentUpdateForm")
);

// Designation Master forms
const DesignationForm = lazy(() =>
  import("./pages/forms/designationMaster/DesignationForm")
);

// Shift Master Forms
const ShiftForm = lazy(() => import("./pages/forms/shiftMaster/ShiftForm"));

// Account master
const GroupForm = lazy(() => import("./pages/forms/accountMaster/GroupForm"));
const LedgerForm = lazy(() => import("./pages/forms/accountMaster/LedgerForm"));
const TallyheadForm = lazy(() =>
  import("./pages/forms/accountMaster/TallyheadForm")
);
const VoucherForm = lazy(() =>
  import("./pages/forms/accountMaster/VoucherForm")
);
const VoucherAssignmentForm = lazy(() =>
  import("./pages/forms/accountMaster/VoucherAssignmentForm")
);
const FinancialReport = lazy(() =>
  import("./pages/forms/accountMaster/FinancialReport")
);
const FinancialReportPrint = lazy(() =>
  import("./pages/forms/accountMaster/FinancialReportPrint")
);
const Financials = lazy(() => import("./pages/forms/accountMaster/Financials"));
const BookBalance = lazy(() =>
  import("./pages/forms/accountMaster/BookBalance")
);
const MonthwiseBookBalance = lazy(() =>
  import("./pages/forms/accountMaster/MonthwiseBookBalance")
);
const DaywiseBookBalance = lazy(() =>
  import("./pages/forms/accountMaster/DaywiseBookBalance")
);
const BookBalanceDetails = lazy(() =>
  import("./pages/forms/accountMaster/BookBalanceDetails")
);
const LedgerReport = lazy(() =>
  import("./pages/forms/accountMaster/LedgerReport")
);
// Academic Calendars
const AcademicyearForm = lazy(() =>
  import("./pages/forms/academicCalendars/AcademicyearForm")
);
const CalenderyearForm = lazy(() =>
  import("./pages/forms/academicCalendars/CalenderyearForm")
);
const FinancialyearForm = lazy(() =>
  import("./pages/forms/academicCalendars/FinancialyearForm")
);

// Salary Master
const SalaryStructureHeadForm = lazy(() =>
  import("./pages/forms/salaryMaster/SalaryStructureHeadForm")
);
const SalaryStructureForm = lazy(() =>
  import("./pages/forms/salaryMaster/SalaryStructureForm")
);
const SalaryStructureAssignment = lazy(() =>
  import("./pages/forms/salaryMaster/SalaryStructureAssignment")
);
const SlabStructureForm = lazy(() =>
  import("./pages/forms/salaryMaster/SlabStructureForm")
);

//Catering Master
const RefreshmentTypeForm = lazy(() =>
  import("./pages/forms/cateringMaster/CreateRefreshmentForm")
);

const RefreshmentRequestReport = lazy(() =>
  import("./pages/forms/cateringMaster/RefreshmentRequestReport")
);

const AssignmentDetailsMaster = lazy(() =>
  import("./pages/forms/cateringMaster/AssignmentDetailsMaster")
);

const MealAssignmentForm = lazy(() =>
  import("./pages/forms/cateringMaster/MealAssignmentForm")
);

const MessAssignmentForm = lazy(() =>
  import("./pages/forms/cateringMaster/MessAssignmentForm.jsx")
);

const RefreshmentRequestIndex = lazy(() =>
  import(
    "./pages/forms/cateringMaster/refreshmentRequest/RefreshmentRequestIndex"
  )
);

const RefreshmentRequestForm = lazy(() =>
  import(
    "./pages/forms/cateringMaster/refreshmentRequest/RefreshmentRequestForm"
  )
);

const RefreshmentDetailsMaster = lazy(() =>
  import(
    "./pages/forms/cateringMaster/refreshmentApprover/RefreshmentMasterDetails"
  )
);

// InventoryMaster

const StoreForm = lazy(() => import("./pages/forms/inventoryMaster/StoreForm"));
const MeasureForm = lazy(() =>
  import("./pages/forms/inventoryMaster/MeasureForm")
);
const VendorForm = lazy(() =>
  import("./pages/forms/inventoryMaster/VendorForm")
);
const View = lazy(() => import("./pages/forms/inventoryMaster/View"));
const PaymentVoucher = lazy(() =>
  import("./pages/forms/inventoryMaster/PaymentVoucher")
);
const SchoolPaymentVoucher = lazy(() =>
  import("./pages/forms/inventoryMaster/SchoolPaymentVoucher")
);
const JournalVoucher = lazy(() =>
  import("./pages/forms/inventoryMaster/JournalVoucher")
);
const DraftPaymentVoucherIndex = lazy(() =>
  import("./containers/indeces/inventoryMaster/DraftPaymentVoucherIndex")
);

const DraftPaymentPdfView = lazy(() =>
  import("./pages/forms/inventoryMaster/DraftPaymentPdfView")
);

const ApproveDraftPayingVoucher = lazy(() =>
  import("./pages/forms/inventoryMaster/ApproveDraftPayingVoucher")
);

const ItemAssignemnt = lazy(() =>
  import("./pages/forms/inventoryMaster/ItemAssignment.jsx")
);

const ItemAssignmentIndex = lazy(() =>
  import("./containers/indeces/inventoryMaster/ItemInstoreIndexNew.jsx")
);

const StoreIndent = lazy(() =>
  import("./pages/forms/inventoryMaster/StoreIndent.jsx")
);

const StoreIndentIndex = lazy(() =>
  import("./containers/indeces/inventoryMaster/StoreIndentIndex.jsx")
);

const StoreIndentApproverIndex = lazy(() =>
  import("./containers/indeces/inventoryMaster/StoreIndentApproverIndex.jsx")
);

const StoreIndentHistory = lazy(() =>
  import("./containers/indeces/inventoryMaster/StoreIndentHistory.jsx")
);

const LibraryBookIssue = lazy(() =>
  import("./pages/forms/inventoryMaster/LibraryBookIssue.jsx")
);

const AccessionBookIndex = lazy(() =>
  import("./containers/indeces/inventoryMaster/AccessionBookIndex.jsx")
);

const LibraryBarCode = lazy(() =>
  import("./pages/forms/inventoryMaster/LibraryBarCode.jsx")
);

const LibraryDetails = lazy(() =>
  import("./pages/forms/studentMaster/LibraryDetails.jsx")
);

const ApproverCreation = lazy(() =>
  import("./pages/forms/inventoryMaster/ApproverCreation.jsx")
);

const ApproverIndex = lazy(() =>
  import("./containers/indeces/inventoryMaster/ApproverIndex.jsx")
);

// Transcript Master Forms
const TranscriptForm = lazy(() =>
  import("./pages/forms/transcriptMaster/TranscriptForm")
);
const TranscriptAssignmentForm = lazy(() =>
  import("./pages/forms/transcriptMaster/TranscriptAssignmentForm")
);
const UniversityForm = lazy(() =>
  import("./pages/forms/transcriptMaster/UniversityForm")
);

const QuestionPaperUpload = lazy(() =>
  import("./pages/forms/studentMaster/QuestionPaperUpload.jsx")
);

const QuestionPaperIndex = lazy(() =>
  import("./containers/indeces/studentMaster/QuestionPaperIndex.jsx")
);

// InfrastructureMaster Forms
const FacilityForm = lazy(() =>
  import("./pages/forms/infrastructureMaster/FacilityForm")
);
const BlockForm = lazy(() =>
  import("./pages/forms/infrastructureMaster/BlockForm")
);
const RoomForm = lazy(() =>
  import("./pages/forms/infrastructureMaster/RoomForm")
);

//Feetemplate Master
const FeeTemplate = lazy(() =>
  import("./pages/forms/feetemplateMaster/FeeTemplate")
);
const FeetemplateSubamount = lazy(() =>
  import("./pages/forms/feetemplateMaster/FeetemplateSubamount")
);
const FeetemplateApproval = lazy(() =>
  import("./pages/forms/feetemplateMaster/FeetemplateApproval")
);
const ViewFeetemplateSubAmount = lazy(() =>
  import("./pages/forms/feetemplateMaster/ViewFeetemplateSubAmount")
);
const FeetemplateAttachmentView = lazy(() =>
  import("./pages/forms/feetemplateMaster/FeetemplateAttachmentView")
);
const FeetemplateApprovalIndex = lazy(() =>
  import("./containers/indeces/feetemplateMaster/FeetemplateApprovalIndex")
);
const FeetemplateSubAmountHistory = lazy(() =>
  import("./pages/forms/feetemplateMaster/FeetemplateSubAmountHistory")
);
const ApplicationFee = lazy(() =>
  import("./pages/forms/feetemplateMaster/ApplicationFee")
);

//Course
const CourseForm = lazy(() => import("./pages/forms/courseMaster/CourseForm"));
const CourseAssignment = lazy(() =>
  import("./pages/forms/courseMaster/CourseAssignment")
);
const CoursePatternForm = lazy(() =>
  import("./pages/forms/courseMaster/CoursePatternForm")
);
const CoursePatternIndex = lazy(() =>
  import("./containers/indeces/courseMaster/CoursePatternIndex")
);
const CourseTypeForm = lazy(() =>
  import("./pages/forms/courseMaster/CourseTypeForm")
);
const CourseCategoryForm = lazy(() =>
  import("./pages/forms/courseMaster/CourseCategoryForm")
);
const CourseStudentAssignment = lazy(() =>
  import("./pages/forms/courseMaster/CourseStudentAssignment")
);
const CourseStudentAssignmentIndex = lazy(() =>
  import("./containers/indeces/courseMaster/CourseStudentAssignmentIndex")
);
const CourseassignmentIndex = lazy(() =>
  import("./containers/indeces/courseMaster/CourseassignmentIndex")
);

const Courseassignmentstudentindex = lazy(() =>
  import("./containers/indeces/courseMaster/CourseAssignmentStudentIndex")
);

// CategoryType Master Forms
const CategoryTypeForm = lazy(() =>
  import("./pages/forms/categoryTypeMaster/CategoryTypeForm")
);
const CategoryDetailsForm = lazy(() =>
  import("./pages/forms/categoryTypeMaster/CategoryDetailsForm")
);
const CommencementTypeForm = lazy(() =>
  import("./pages/forms/categoryTypeMaster/CommencementTypeForm")
);

//LeaveMaster Forms
const LeaveTypeForm = lazy(() =>
  import("./pages/forms/leaveMaster/LeaveTypeForm")
);
const InitialLeave = lazy(() =>
  import("./pages/forms/leaveMaster/LeaveApplyAdminForm")
);
const ViewLeavePDF = lazy(() =>
  import("./pages/forms/leaveMaster/ViewLeavePDF")
);
const LeaveApplyForm = lazy(() =>
  import("./pages/forms/leaveMaster/LeaveApplyForm")
);
const LeaveApplyIndex = lazy(() =>
  import("./containers/indeces/leaveMaster/LeaveApplyIndex")
);
const LeaveApproverIndex = lazy(() =>
  import("./containers/indeces/leaveMaster/LeaveApproverIndex")
);
const LeaveApprovedHistoryIndex = lazy(() =>
  import("./containers/indeces/leaveMaster/LeaveApprovedHistoryIndex")
);
const LeaveApplyAdminIndex = lazy(() =>
  import("./containers/indeces/leaveMaster/LeaveApplyAdminIndex")
);

// HolidayCalenderMaster Forms
const HolidayCalenderForm = lazy(() =>
  import("./pages/forms/holidayCalenderMaster/HolidayCalenderForm")
);
const DeAssignDepartment = lazy(() =>
  import("./pages/forms/holidayCalenderMaster/DeAssignDepartment")
);

//LeavePattern Master Forms
const LeavePatternForm = lazy(() =>
  import("./pages/forms/leavePatternMaster/LeavePatternForm")
);
const ViewReport = lazy(() =>
  import("./pages/forms/leavePatternMaster/ViewReport")
);

// HostelMaster Forms
const DoctorWardenForm = lazy(() =>
  import("./pages/forms/hostelMaster/DoctorWardenForm")
);
const HostelBlockForm = lazy(() =>
  import("./pages/forms/hostelMaster/HostelBlockForm")
);
const RoomTypeForm = lazy(() =>
  import("./pages/forms/hostelMaster/RoomTypeForm")
);
const HostelRoomForm = lazy(() =>
  import("./pages/forms/hostelMaster/HostelRoomForm")
);
const StandardAccessoriesForm = lazy(() =>
  import("./pages/forms/hostelMaster/StandardAccessoriesForm")
);

// Section Master forms
const SectionForm = lazy(() =>
  import("./pages/forms/sectionMaster/SectionForm")
);
const BatchForm = lazy(() => import("./pages/forms/sectionMaster/BatchForm"));
const SectionAssignmentForm = lazy(() =>
  import("./pages/forms/sectionMaster/SectionAssignmentForm")
);
const StudentPromote = lazy(() =>
  import("./pages/forms/sectionMaster/StudentPromote")
);
const TimeSlotsForm = lazy(() =>
  import("./pages/forms/sectionMaster/TimeSlotsForm")
);
const CourseAssignmentForm = lazy(() =>
  import("./pages/forms/timeTableMaster/CourseAssignmentForm")
);
const TimeIntervalTypesForm = lazy(() =>
  import("./pages/forms/sectionMaster/TimeIntervalTypesForm")
);
const TimetableForSectionForm = lazy(() =>
  import("./pages/forms/timeTableMaster/TimetableForSectionForm")
);
const TimetableForBatchForm = lazy(() =>
  import("./pages/forms/timeTableMaster/TimetableForBatchForm")
);

const RecruitmentNew = lazy(() =>
  import("./pages/forms/jobPortal/RecruitmentNewFor")
);

//Mentor Master
const ProctorheadForm = lazy(() =>
  import("./pages/forms/mentorMaster/ProctorheadForm")
);
const ProctorStudentAssignmentForm = lazy(() =>
  import("./pages/forms/mentorMaster/ProctorStudentAssignmentForm")
);
const ProctorStudentAssignmentIndex = lazy(() =>
  import("./containers/indeces/mentorMaster/ProctorStudentAssignmentIndex")
);

const ProctorStudentHistory = lazy(() =>
  import("./containers/indeces/mentorMaster/ProctorStudentHistory.jsx")
);

const ProctorMeeting = lazy(() =>
  import("./pages/forms/mentorMaster/ProctorMeeting.jsx")
);

const ProctorStudentMeeting = lazy(() =>
  import("./pages/forms/mentorMaster/ProctorStudentMeeting.jsx")
);

const ProctorStudentMeetingIndex = lazy(() =>
  import("./containers/indeces/mentorMaster/ProctorStudentMeetingIndex.jsx")
);

const ProctorheadIndex = lazy(() =>
  import("./containers/indeces/mentorMaster/ProctorheadIndex.jsx")
);

//Student Master
const ExtraRemuneration = lazy(() =>
  import("./pages/forms/studentMaster/ExtraRemuneration")
);
const StudentAttendace = lazy(() =>
  import("./pages/forms/studentMaster/StudentAttendace.jsx")
);
const StudentAssessment = lazy(() =>
  import("./pages/forms/studentMaster/StudentAssessment.jsx")
);
const StudentAttendaceReport = lazy(() =>
  import("./pages/indeces/StudentAttendaceReport")
);
const ExtraRemunerationIndex = lazy(() =>
  import("./pages/indeces/ExtraRemunerationIndex")
);
const LessonplanForm = lazy(() =>
  import("./pages/forms/studentMaster/LessonplanForm")
);
const Referencebookform = lazy(() =>
  import("./pages/forms/studentMaster/ReferencebookForm")
);
const LessonplanIndex = lazy(() =>
  import("./containers/indeces/studentMaster/LessonplanIndex")
);
const ReferencebookIndex = lazy(() =>
  import("./containers/indeces/studentMaster/ReferencebookIndex")
);
const DollartoSomConversion = lazy(() =>
  import("./pages/forms/studentMaster/DollartoSomConversion")
);
const DollartosomIndex = lazy(() =>
  import("./containers/indeces/studentMaster/DollartosomIndex")
);
const ApplicationFeeIndex = lazy(() =>
  import("./containers/indeces/feetemplateMaster/ApplicationFeeIndex")
);
const HallticketPdf = lazy(() =>
  import("./pages/forms/studentMaster/HallticketPdf")
);
const DownloadHallTicket = lazy(() =>
  import("./pages/forms/studentMaster/DownloadHallTicket")
);
const FeeReceipt = lazy(() => import("./pages/forms/studentMaster/FeeReceipt"));
const StudentFeeReceipt = lazy(() =>
  import("./pages/forms/studentMaster/StudentFeeReceipt")
);
const FeeReceiptDetails = lazy(() =>
  import("./pages/forms/studentMaster/FeeReceiptDetails")
);
const StudentFeeReceiptDetailsPDF = lazy(() =>
  import("./pages/forms/studentMaster/StudentFeeReceiptPDF.jsx")
);
const FeeReceiptDetailsPDF = lazy(() =>
  import("./pages/forms/studentMaster/FeeReceiptDetailsPDF")
);

const FeeReceiptIndex = lazy(() =>
  import("./containers/indeces/studentMaster/StudentFeereceiptIndex")
);

const BulkFeeReceipt = lazy(() =>
  import("./pages/forms/studentMaster/BulkFeeReceipt")
);
const BulkFeeReceiptView = lazy(() =>
  import("./pages/forms/studentMaster/BulkFeeReceiptView")
);
const BulkFeeReceiptPdf = lazy(() =>
  import("./pages/forms/studentMaster/BulkFeeReceiptPdf")
);
const BulkFeeReceiptForm = lazy(() =>
  import("./pages/forms/studentMaster/BulkFeeReceiptForm")
);

const CancelFeeReceipt = lazy(() =>
  import("./pages/forms/studentMaster/CancelFeeReceipt")
);

const CancelFeeReceiptIndex = lazy(() =>
  import("./containers/indeces/studentMaster/CancelReceiptIndex.jsx")
);

const HallTicketPdfRussian = lazy(() =>
  import("./pages/forms/studentMaster/HallTicketPdfRussian")
);
const HallTicketPdfUzbek = lazy(() =>
  import("./pages/forms/studentMaster/HallTicketPdfUzbek")
);
const HallticketDownloadedIndex = lazy(() =>
  import("./containers/indeces/studentMaster/HallticketDownloadedIndex")
);
const StudentDetailsView = lazy(() =>
  import("./components/StudentDetailsView.jsx")
);
const StudentDetailsViewAttendance = lazy(() =>
  import("./components/StudentDetailsViewAttendance.jsx")
);

const ImportBioTrans = lazy(() =>
  import("./pages/forms/studentMaster/ImportBioTranse")
);

const StudentPaymentMaster = lazy(() =>
  import("./pages/masters/StudentPaymentMaster.jsx")
);

const InactiveStudentsIndex = lazy(() =>
  import("./containers/indeces/studentDetailMaster/InactiveStudentIndex")
);

//Report Master
const ReportForm = lazy(() =>
  import("./pages/forms/studentReportingMaster/ReportForm")
);
const ReportIndex = lazy(() =>
  import("./containers/indeces/studentReportingMaster/ReportIndex")
);
const StudentEligibleForm = lazy(() =>
  import("./pages/forms/studentReportingMaster/StudentEligibleForm")
);
const StudentEligibleIndex = lazy(() =>
  import("./containers/indeces/studentReportingMaster/StudentEligibleIndex")
);
const StudentPromoteForm = lazy(() =>
  import("./pages/forms/studentReportingMaster/StudentPromoteForm")
);
const StudentPromoteIndex = lazy(() =>
  import("./containers/indeces/studentReportingMaster/StudentPromoteIndex")
);
const StudentHistory = lazy(() =>
  import("./pages/forms/studentReportingMaster/StudentHistory")
);
const StudentHistoryIndex = lazy(() =>
  import("./containers/indeces/studentReportingMaster/StudentHistoryIndex")
);

// Candidate Walkin
const CandidateWalkinIndex = lazy(() =>
  import("./containers/indeces/candidatewalkinMaster/CandidateWalkinIndex")
);

const CandidateWalkinForm = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateWalkinForm")
);
const PreAdmissionProcessForm = lazy(() =>
  import("./pages/forms/candidateWalkin/PreAdmissionProcessForm")
);
const PreScholarshipApproverForm = lazy(() =>
  import("./pages/forms/candidateWalkin/PreScholarshipApproverForm")
);
const PreScholarshipVerifierForm = lazy(() =>
  import("./pages/forms/candidateWalkin/PreScholarshipVerifierForm")
);
const OfferLetterView = lazy(() =>
  import("./pages/forms/candidateWalkin/OfferLetterView")
);
const AuidForm = lazy(() => import("./pages/forms/candidateWalkin/AuidForm"));
const StudentDocumentCollectionPdf = lazy(() =>
  import("./components/StudentDocumentCollectionPdf")
);
const CandidateOfferLetterPdf = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateOfferLetterPdf")
);
const DirectScholarshipForm = lazy(() =>
  import("./pages/forms/candidateWalkin/DirectScholarshipForm")
);
const ScholarshipApproverForm = lazy(() =>
  import("./pages/forms/candidateWalkin/ScholarshipApproverForm")
);
const CandidateWalkinTempIndex = lazy(() =>
  import("./pages/indeces/CandidateWalkinTempIndex")
);
const CandidateApplication = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateApplication")
);
const CandidateDocumentsView = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateDocumentsView")
);
const CandidateApplicationPdf = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateApplicationPdf")
);
const EntranceExamDetails = lazy(() =>
  import("./pages/forms/candidateWalkin/EntranceExamDetails")
);
const EntranceExamDetailsIndex = lazy(() =>
  import("./pages/indeces/EntranceExamDetailsIndex")
);
const CandidateProgramSummary = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateProgramSummary")
);
const ExamDateSummary = lazy(() =>
  import("./pages/forms/candidateWalkin/ExamDateSummary")
);

const LeaveSummary = lazy(() =>
  import("./pages/forms/leaveMaster/LeaveSummary.jsx")
);

const CandidateEditApplication = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateEditApplication")
);
const CandidateImportResult = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateImportResult")
);
const CandidateApplicationFormView = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateApplicationFormView")
);
const CandidateWalkinResultMaster = lazy(() =>
  import("./pages/masters/CandidateWalkinResultMaster")
);
const CandidateAdmissionForm = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateAdmissionForm")
);
const CandidateWalkinOfferIndex = lazy(() =>
  import("./pages/indeces/CandidateWalkinDirectIndex")
);
const CandidateContractUploadDownload = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateContractUploadDownload")
);
const CandidateContractPdf = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateContractPdf")
);
const CandidateFoundationPathForm = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateFoundationPathForm")
);
const CandidateTransferInForm = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateTransferInForm")
);
const ScholarshipApplicationPrint = lazy(() =>
  import("./pages/forms/candidateWalkin/ScholarshipApplicationPrint")
);
const PaymentAllowForm = lazy(() =>
  import("./pages/forms/candidateWalkin/PaymentAllowForm")
);
const PaymentAllowIndex = lazy(() =>
  import("./pages/indeces/PaymentAllowIndex")
);
const ExternalPaymentForm = lazy(() =>
  import("./pages/forms/candidateWalkin/ExternalPaymentForm")
);
const ExternalPaymentSuccessPrint = lazy(() =>
  import("./pages/forms/candidateWalkin/ExternalPaymentSuccessPrint")
);
const ExternalPaymentReport = lazy(() =>
  import("./pages/forms/candidateWalkin/ExternalPaymentReport")
);
const RegistrationSummary = lazy(() =>
  import("./pages/forms/candidateWalkin/RegistrationSummary")
);
// HostelFee Master Forms
const HostelFeeForm = lazy(() =>
  import("./pages/forms/hostelFeeMaster/HostelFeeForm")
);
const ViewFeeTemplate = lazy(() =>
  import("./pages/forms/hostelFeeMaster/ViewFeeTemplate")
);

// Student Feedback Master Forms
const StudentFeedbackForm = lazy(() =>
  import("./pages/forms/studentFeedbackMaster/StudentFeedbackForm")
);

// TimeTable Master Forms
const BatchAssignmentForm = lazy(() =>
  import("./pages/forms/timeTableMaster/BatchAssignmentForm")
);
const TimeTabeleView = lazy(() =>
  import("./pages/forms/timeTableMaster/TimeTableView")
);
const TimeTableViewDateWise = lazy(() =>
  import("./pages/forms/timeTableMaster/TimeTableViewDateWise")
);
const TimeTableDateWisePDF = lazy(() =>
  import("./pages/forms/timeTableMaster/TimeTableDateWisePDF")
);
const TimeTableViewForCourse = lazy(() =>
  import("./pages/forms/timeTableMaster/TimeTableViewForCourse")
);
const TimeTableViewWeekWise = lazy(() =>
  import("./pages/forms/timeTableMaster/TimeTableViewWeekWise")
);
const TimeTableFacultyViewPDF = lazy(() =>
  import("./pages/forms/timeTableMaster/TimeTableFacultyViewPDF")
);
const TimeTableViewWeekWisePdf = lazy(() =>
  import("./pages/forms/timeTableMaster/TimeTableViewWeekWisePdf")
);

//Student Details Master forms
const ProvisionCertificate = lazy(() =>
  import("./pages/forms/studentDetailMaster/ProvisionCertificate")
);
const ProvisionCertificatePdf = lazy(() =>
  import("./pages/forms/studentDetailMaster/ProvisionCertificatePdf")
);
const DocumentCollectionForm = lazy(() =>
  import("./pages/forms/studentDetailMaster/DocumentCollectionForm")
);
const CancelAdmissionInitiate = lazy(() =>
  import("./pages/forms/studentDetailMaster/CancelAdmissionInitiate")
);

const CancelAdmissionDetails = lazy(() =>
  import("./pages/forms/studentDetailMaster/CancelAdmissionDetails")
);

const ApprovalCancelAdmission = lazy(() =>
  import("./pages/forms/studentDetailMaster/ApprovalCancelAdmission")
);

const CancelStudentDeatils = lazy(() =>
  import("./pages/forms/studentDetailMaster/CancelledStudentDeatils")
);

const CancelAdmissionApprover = lazy(() =>
  import("./pages/forms/studentDetailMaster/CancelAdmissionApprover")
);
const StudentTranscriptApplication = lazy(() =>
  import("./components/StudentTranscriptApplication")
);

const GrantPrintApplication = lazy(() =>
  import("./pages/forms/studentDetailMaster/GrantPrintApplication")
);
const StudentDuePaymentForm = lazy(() =>
  import("./pages/forms/studentDetailMaster/StudentDuePaymentForm")
);
// Student transcrtipt master forms
const StudentTranscriptForm = lazy(() =>
  import("./pages/forms/studentTranscriptsMaster/StudentTranscriptForm")
);

//Bank Master
const BankForm = lazy(() => import("./pages/forms/bankMaster/BankForm"));
const BankImport = lazy(() => import("./pages/forms/bankMaster/BankImport"));
const BankClearedHistory = lazy(() =>
  import("./containers/indeces/bankMaster/BankImportClearedHistory")
);
const BankIndex = lazy(() =>
  import("./containers/indeces/bankMaster/BankIndex.jsx")
);

//Student Intake
const StudentIntakeForm = lazy(() =>
  import("./pages/forms/studentIntake/StudentIntakeForm")
);
const StudentIntakeSelection = lazy(() =>
  import("./pages/forms/studentIntake/StudentIntakeSelectionForm")
);
const StudentIntakeSummary = lazy(() =>
  import("./pages/forms/studentIntake/StudentIntakeSummary")
);

// ExitForm Master Forms
const ExitForm = lazy(() => import("./pages/forms/exitFormMaster/ExitForm"));
const ExitQuestionsForm = lazy(() =>
  import("./pages/forms/exitFormMaster/ExitQuestionsForm")
);

// Event Master Forms
const EventCreationForm = lazy(() =>
  import("./pages/forms/eventMaster/EventCreationForm")
);
const SessionRoomInvigilatorAssignment = lazy(() =>
  import("./pages/forms/academicMaster/SessionRoomInvigilatorAssignment")
);

// AcademicSection Master Forms
const ClassCommencementForm = lazy(() =>
  import("./pages/forms/academicSectionMaster/ClassCommencementForm")
);
const SessionStudentAssignmentIndex = lazy(() =>
  import("./containers/indeces/academicMaster/SessionStudentAssignmentIndex")
);
const SessionCourseAndDateMappingIndex = lazy(() =>
  import("./containers/indeces/academicMaster/SessionCourseAndDateMappingIndex")
);

//Time Table Reports
const FacultyWorkload = lazy(() =>
  import("./pages/forms/timeTableMaster/FacultyWorkload")
);
const FacultyWorkloadDaywise = lazy(() =>
  import("./pages/forms/timeTableMaster/FacultyWorkloadDaywise")
);

//Course Subjective Master
const CourseObjectiveForm = lazy(() =>
  import("./pages/forms/courseMaster/CourseObjectiveForm")
);
const CourseOutcomeForm = lazy(() =>
  import("./pages/forms/courseMaster/CourseOutcomeForm")
);
const SyllabusForm = lazy(() =>
  import("./pages/forms/academicMaster/SyllabusForm")
);
const ChangePassword = lazy(() => import("./pages/ChangePassword"));

// Employee Master
const EmpManualAttendance = lazy(() =>
  import("./pages/forms/employeeMaster/EmpManualAttendance")
);

const InternaltimeTableAttendaceReport = lazy(() =>
  import("./pages/masters/InternalTimeTableAttendanceReport.jsx")
);
const EmpAttendanceTrigger = lazy(() =>
  import("./pages/forms/employeeMaster/EmpAttendanceTrigger")
);

const EmpAttendanceFilterForm = lazy(() =>
  import("./pages/forms/employeeMaster/EmpAttendanceFilterForm")
);
const SalaryLockForm = lazy(() =>
  import("./pages/forms/employeeMaster/SalaryLockForm")
);
const SalaryLockIndex = lazy(() => import("./pages/indeces/SalaryLockIndex"));
const EmployeeResignationIndex = lazy(() =>
  import("./pages/indeces/EmployeeResignationIndex")
);
const EmpResignationForm = lazy(() =>
  import("./pages/forms/employeeMaster/EmpResignationForm")
);
const PayslipReportPdf = lazy(() =>
  import("./pages/forms/employeeMaster/PayslipReportPdf")
);
const CandidateRegistrationForm = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateRegistrationForm")
);
const CandidateRegistrationDirectForm = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateRegistrationDirectForm")
);
const CandidateRegistration = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateRegistration")
);

const CandidateRegistrationStep1 = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateRegistrationStep1")
);
const TemplateMaster = lazy(() => import("./pages/masters/TemplateMaster"));
const SMSForm = lazy(() => import("./pages/forms/templateMaster/SMSForm"));
const CandidateWalkinMaster = lazy(() =>
  import("./pages/masters/CandidateWalkinMaster")
);
const CandidateRegistrationEditForm = lazy(() =>
  import("./pages/forms/candidateWalkin/CandidateRegistrationEditForm")
);
const PreGrantApproveMaster = lazy(() =>
  import("./pages/masters/PreGrantApproveMaster")
);
const PreGrantVerifyMaster = lazy(() =>
  import("./pages/masters/PreGrantVerifyMaster")
);
const ScholarshipApproverIndex = lazy(() =>
  import("./pages/indeces/ScholarshipApproverIndex")
);
const ScholarshipApproverHistory = lazy(() =>
  import("./pages/indeces/ScholarshipApproverHistory")
);

const AdvanceDeductionForm = lazy(() =>
  import("./pages/forms/employeeMaster/AdvanceDeductionForm.jsx")
);

const AdvanceDeductionIndex = lazy(() =>
  import("./pages/indeces/AdvanceDeductionIndex.jsx")
);

// Journal Voucher
const JournalVoucherForm = lazy(() =>
  import("./pages/forms/journals/JournalVoucherForm")
);

const SchedulerMaster = lazy(() => import("./components/SchedulerMaster.jsx"));

const FacultyDetails = lazy(() => import("./pages/masters/FacultyDetails.jsx"));

const InternaltimeTable = lazy(() =>
  import("./pages/masters/InternalTimeTable.jsx")
);

const StudentDetailsByBatch = lazy(() =>
  import("./pages/masters/StudentDetailsByBatch.jsx")
);

const FacultyDetailsAttendaceReport = lazy(() =>
  import("./pages/masters/FacultyDetailsAttendanceReportView.jsx")
);

//Payment Master
const DraftpaymentVoucher = lazy(() =>
  import("./pages/forms/inventoryMaster/SchoolPaymentVoucher")
);

//Transport Master

const VehicleRouteForm = lazy(() =>
  import("./pages/forms/TransportMaster/VehicleRouteForm")
);
const TransportAssignment = lazy(() =>
  import("./pages/forms/TransportMaster/TransportAssignment")
);

const RouteConfig = () => {
  const token = JSON.parse(localStorage.getItem("AcharyaErpUser"))?.token;

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            token ? (
              <Navigate replace to="/Dashboard" />
            ) : (
              <Navigate replace to="/Login" />
            )
          }
        />

        <Route
          exact
          path="/Login"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <Login />
            </Suspense>
          }
        ></Route>
        <Route
          exact
          path="/ForgotPassword"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <ForgotPassword />
            </Suspense>
          }
        />

        <Route
          exact
          path="/ResetPassword"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          element={
            <Suspense fallback={<OverlayLoader />}>
              <NavigationLayout />
            </Suspense>
          }
        >
          <Route
            exact
            path="/employeeIdCard"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <EmployeeIdCard />
              </Suspense>
            }
          />
          <Route
            exact
            path="/studentIdCard"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <StudentIdCard />
              </Suspense>
            }
          />
          <Route
            exact
            path="/documentsrepo"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <DocumentsRepo />
              </Suspense>
            }
          />
          <Route
            exact
            path="/documentsrepo/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <DocumentList />
              </Suspense>
            }
          />
          <Route
            exact
            path="/documentsrepo/custom-template"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CustomTemplate />
              </Suspense>
            }
          />
          <Route
            exact
            path="/FormExample"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <FormExample />
              </Suspense>
            }
          />
          <Route
            exact
            path={"/charts-dashboard"}
            element={
              <Suspense fallback={<OverlayLoader />}>
                <ChartsDashboard />
              </Suspense>
            }
          />
          {[
            { path: "/charts-dashboard/hrm", comp: <HRMPage /> },
            { path: "/charts-dashboard/finance", comp: <FinancePage /> },
            { path: "/charts-dashboard/admission", comp: <AdmissionPage /> },
          ].map((obj) => (
            <Route
              exact
              key={obj.path}
              path={obj.path}
              element={
                <Suspense fallback={<OverlayLoader />}>{obj.comp}</Suspense>
              }
            />
          ))}
          <Route
            exact
            path="/Dashboard"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <SchedulerMaster />
              </Suspense>
            }
          />
          <Route
            exact
            path="/Profile"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            exact
            path="/ChangePassword"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <ChangePassword />
              </Suspense>
            }
          />
          {/* Institute Master */}
          <>
            <Route
              exact
              path={"/InstituteMaster"}
              element={<Navigate replace to="/InstituteMaster/Organization" />}
            />
            {[
              "/InstituteMaster/Organization",
              "/InstituteMaster/School",
              "/InstituteMaster/JobType",
              "/InstituteMaster/EmpType",
              "/InstituteMaster/Graduation",
              "/InstituteMaster/Visions",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <InstituteMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/InstituteMaster/Emptype/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmptypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InstituteMaster/Emptype/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmptypeForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InstituteMaster/Jobtype/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <JobtypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InstituteMaster/Jobtype/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <JobtypeForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InstituteMaster/Organization/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <OrganizationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InstituteMaster/Organization/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <OrganizationForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InstituteMaster/School/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SchoolForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InstituteMaster/School/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SchoolForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InstituteMaster/Graduation/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <GraduationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InstituteMaster/Graduation/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <GraduationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InstituteMaster/SchoolVision/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SchoolVisionForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InstituteMaster/SchoolVision/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SchoolVisionForm />
                </Suspense>
              }
            />
          </>
          {/* Navigation Master */}
          <>
            <Route
              exact
              path={"/NavigationMaster"}
              element={<Navigate replace to="/NavigationMaster/Module" />}
            />
            {[
              "/NavigationMaster/Module",
              "/NavigationMaster/Menu",
              "/NavigationMaster/Submenu",
              "/NavigationMaster/Role",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <NavigationMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/NavigationMaster/Module/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ModuleForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/NavigationMaster/Module/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ModuleForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/NavigationMaster/Menu/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MenuForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/NavigationMaster/Menu/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MenuForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/NavigationMaster/Submenu/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SubmenuForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/NavigationMaster/Submenu/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SubmenuForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/NavigationMaster/Role/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RoleForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/NavigationMaster/Role/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RoleForm />
                </Suspense>
              }
            />
          </>
          {/*Salary Master */}
          <>
            <Route
              exact
              path={"/SalaryMaster"}
              element={<Navigate replace to="/SalaryMaster/SalaryStructure" />}
            />
            {[
              "/SalaryMaster/SalaryStructure",
              "/SalaryMaster/SalaryHead",
              "/SalaryMaster/Assignment",
              "/SalaryMaster/SlabDefinition",
              "/SalaryMaster/SlabStructure",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <SalaryMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/SalaryMaster/SalaryStructure/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryStructureForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryMaster/SalaryStructure/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryStructureForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryMaster/SalaryStructureHead/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryStructureHeadForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryMaster/SalaryStructureHead/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryStructureHeadForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryMaster/SalaryStructureAssignment/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryStructureAssignment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryMaster/SalaryStructureAssignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryStructureAssignment />
                </Suspense>
              }
            />
            <Route
              exact
              path="SlabStructureForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SlabStructureForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="SlabStructureUpdate/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SlabStructureForm />
                </Suspense>
              }
            />
          </>
          {/* Service Type */}

          <>
            <Route
              exact
              path={"/ServiceMaster"}
              element={<Navigate replace to="/ServiceMaster/ServiceTypes" />}
            />
            {[
              "/ServiceMaster/ServiceTypes",
              "/ServiceMaster/ServiceAssignment",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <ServiceMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path={"/ServiceRender"}
              element={<Navigate replace to="/ServiceRender/AttendRequest" />}
            />
            {[
              "/ServiceRender/AttendRequest",
              "/ServiceRender/AttendHistory",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <AttendServiceMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/ServiceMaster/ServiceTypes/new"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ServiceTypeForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ServiceMaster/ServiceTypes/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ServiceTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ServiceMaster/ServiceAssignment/new"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ServiceAssignmentForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ServiceRequest"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ServiceRequestIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ServiceRequest/new"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CreateServiceReqForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ServiceRender/attend"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AttendServiceRequest />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ServiceRender/AttendRequest"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AttendServiceRendorIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ServiceRender/AttendHistory"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AttendServiceHistory />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ServiceMasterReport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AttendRequestMaster />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ServiceMasterCharts"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ServiceRequestGraph />
                </Suspense>
              }
            />
          </>

          {/*Academic Master */}
          <>
            <Route
              exact
              path={"/AcademicMaster"}
              element={<Navigate replace to="/AcademicMaster/Department" />}
            />
            {[
              "/AcademicMaster/Department",
              "/AcademicMaster/Assignment",
              "/AcademicMaster/Program",
              "/AcademicMaster/Assign",
              "/AcademicMaster/Specialization",
              "/AcademicMaster/Internal",
              "/AcademicMaster/VisionMissions",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <AcademicMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/AcademicMaster/Department/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DepartmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/Department/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DepartmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/DepartmentAssignment/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DepartmentAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/DepartmentAssignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DepartmentAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/Program/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProgramForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/Program/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProgramForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/ProgramAssignment/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProgramAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/ProgramAssignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProgramAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/ProgramSpecialization/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProgramSpecializationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/ProgramSpecialization/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProgramSpecializationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/VisionMission/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VisionMissionForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicMaster/VisionMission/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VisionMissionForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InternalTimetablePdf/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternalTimetablePdf />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InternalStudentsPdf"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternalStudentsPdf />
                </Suspense>
              }
            />

            <Route
              exact
              path="/SessionMarksEntryForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SessionMarksEntryForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SessionMarksEntry/:acYearId/:schoolId/:programSpeId/:programId/:yearsemId/:sectionId/:courseId/:internalId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SessionMarksEntry />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentOnlineClass"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentOnlineClass />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InternalAssignment"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternalAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InternalAssignmentIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternalAssignmentIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InternalRoomAssignment"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternalRoomAssignment />
                </Suspense>
              }
            />
          </>
          {/* Admission Master */}
          <>
            <Route
              exact
              path={"/AdmissionMaster"}
              element={<Navigate replace to="/AdmissionMaster/Course" />}
            />
            {[
              "/AdmissionMaster/Course",
              "/AdmissionMaster/Board",
              "/AdmissionMaster/Category",
              "/AdmissionMaster/Sub",
              "/AdmissionMaster/Currency",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <AdmissionMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/AdmissionMaster/AdmissionCategory/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AdmCategoryForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/AdmissionCategory/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AdmCategoryForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/AdmissionSubCategory/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AdmSubCategoryForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/AdmissionSubCategory/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AdmSubCategoryForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/Board/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BoardForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/Board/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BoardForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/Currencytype/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CurrencytypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/Currencytype/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CurrencytypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/Programtype/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProgramtypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AdmissionMaster/Programtype/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProgramtypeForm />
                </Suspense>
              }
            />
          </>
          {/* Template Master */}
          <>
            <Route
              exact
              path="/TemplateMaster"
              element={<Navigate replace to="/TemplateMaster/Templates" />}
            />
            {["TemplateMaster/Templates"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <TemplateMaster />{" "}
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/TemplateMaster/Templates/New/SMSTemplate"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SMSForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TemplateMaster/Templates/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SMSForm />
                </Suspense>
              }
            />
          </>
          {/* Designation Master */}
          <>
            <Route
              exact
              path="/DesignationMaster"
              element={
                <Navigate replace to="/DesignationMaster/Designations" />
              }
            />
            {["DesignationMaster/Designations"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <DesignationMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/DesignationMaster/Designations/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DesignationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/DesignationMaster/Designations/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DesignationForm />
                </Suspense>
              }
            />
          </>
          {/* Shift Master */}
          <>
            <Route
              exact
              path="/ShiftMaster"
              element={<Navigate replace to="/ShiftMaster/Shifts" />}
            />
            {["ShiftMaster/Shifts"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <ShiftMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/ShiftMaster/Shifts/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ShiftForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ShiftMaster/Shifts/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ShiftForm />
                </Suspense>
              }
            />
          </>
          {/* Academic Calenders */}
          <>
            <Route
              exact
              path={"/AcademicCalendars"}
              element={
                <Navigate replace to="/AcademicCalendars/AcademicYear" />
              }
            />
            {[
              "/AcademicCalendars/AcademicYear",
              "/AcademicCalendars/FinancialYear",
              "/AcademicCalendars/CalendarYear",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <AcademicCalendars />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/AcademicCalendars/Academicyear/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AcademicyearForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicCalendars/Academicyear/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AcademicyearForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicCalendars/Financialyear/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FinancialyearForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicCalendars/Financialyear/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FinancialyearForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicCalendars/Calenderyear/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CalenderyearForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicCalendars/Calenderyear/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CalenderyearForm />
                </Suspense>
              }
            />
          </>
          {/*Account Master */}
          <>
            <Route
              exact
              path={"/AccountMaster"}
              element={<Navigate replace to="/AccountMaster/Group" />}
            />
            {[
              "/AccountMaster/Group",
              "/AccountMaster/Ledger",
              "/AccountMaster/Tallyhead",
              "/AccountMaster/Voucherhead",
              "/AccountMaster/Assignment",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <AccountMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/AccountMaster/Group/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <GroupForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/Group/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <GroupForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/Ledger/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LedgerForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/Ledger/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LedgerForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/Tallyhead/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TallyheadForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/Tallyhead/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TallyheadForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/Voucher/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VoucherForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/Voucher/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VoucherForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/VoucherAssignment/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VoucherAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AccountMaster/VoucherAssignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VoucherAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FinancialReport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FinancialReport />
                </Suspense>
              }
            />
            <Route
              exact
              path="/Financials"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <Financials />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BookBalance"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BookBalance />
                </Suspense>
              }
            />
            <Route
              exact
              path="/MonthwiseBookBalance/:schoolId/:fcyearId/:bankId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MonthwiseBookBalance />
                </Suspense>
              }
            />
            <Route
              exact
              path="/DaywiseBookBalance/:schoolId/:fcyearId/:bankId/:month"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DaywiseBookBalance />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BookBalanceDetails/:schoolId/:fcyearId/:bankId/:date/:type"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BookBalanceDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LedgerReport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LedgerReport />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FinancialReportPrint"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FinancialReportPrint />
                </Suspense>
              }
            />
          </>
          {/* Job Portal  */}
          <>
            <Route
              exact
              path="/UserIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <UserIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/UserForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <UserForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/JobPortal"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <JobPortalIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/Interview/New/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InterView />
                </Suspense>
              }
            />
            <Route
              exact
              path="/Interview/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InterView />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ResultForm/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ResultForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryBreakupForm/New/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryBreakupForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryBreakupForm/Update/:id/:offerId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryBreakupForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/OfferForm/:id/:offerId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <OfferForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/Recruitment/:id/:offerId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RecruitmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HodComments"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HodCommentsIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/EmployeeIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/EmployeeLeaveDetails"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeLeaveDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path="/EmployeeCalender"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeCalendar />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryBreakupForm/New/:id/:offerId/:type"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryBreakupForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/Newjoineeapprover"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeSalaryApprovalIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/EmployeeSalaryApprove/:empId/:offerId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeSalaryApprove />
                </Suspense>
              }
            />

            {/* <Route
              exact
              path="/EmployeeIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeIndex />
                </Suspense>
              }
            /> */}
            <Route
              exact
              path="/JournalIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <JournalIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/JournalIndex/JournalVoucherNew"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <JournalVoucherNew />
                </Suspense>
              }
            />

            <Route
              exact
              path="/EmployeeDetailsView/:userId/:offerId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeDetailsView />
                </Suspense>
              }
            />

            <Route
              exact
              path="/MyProfile"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MyProfile />
                </Suspense>
              }
            />

            <Route
              exact
              path="/EmployeeUpdateForm/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeUpdateForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/RecruitmentUpdateForm/:id/:offerId/:jobId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RecruitmentUpdateForm />
                </Suspense>
              }
            />
          </>
          {/* Candidate Walkin  */}
          <Route
            exact
            path="/CandidateWalkinIndex"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CandidateWalkinIndex />
              </Suspense>
            }
          />
          <Route
            exact
            path="/CandidateWalkinForm"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CandidateWalkinForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/PreAdmissionProcessForm/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PreAdmissionProcessForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/PreScholarshipApproverForm/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PreScholarshipApproverForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/PreScholarshipVerifierForm/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PreScholarshipVerifierForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/offerletterview/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <OfferLetterView />
              </Suspense>
            }
          />
          <Route
            exact
            path="/AuidForm/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <AuidForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/DirectScholarshipForm"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <DirectScholarshipForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/ScholarshipApproverForm/:studentId/:scholarshipId"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <ScholarshipApproverForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/CandidateWalkinTempIndex"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CandidateWalkinTempIndex />
              </Suspense>
            }
          />
          <Route
            exact
            path="/CandidateProgramSummary"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CandidateProgramSummary />
              </Suspense>
            }
          />
          <Route
            exact
            path="/ExamDateSummary"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <ExamDateSummary />
              </Suspense>
            }
          />

          <Route
            exact
            path="/CandidateEditApplication/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CandidateEditApplication />
              </Suspense>
            }
          />
          <Route
            exact
            path="/CandidateImportResult"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CandidateImportResult />
              </Suspense>
            }
          />
          <Route
            exact
            path="/ScholarshipApplicationPrint/:studentId/:scholarshipId"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <ScholarshipApplicationPrint />
              </Suspense>
            }
          />
          <Route
            exact
            path="/PaymentAllowForm/New"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PaymentAllowForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/PaymentAllowForm/Update/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PaymentAllowForm />
              </Suspense>
            }
          />
          <Route
            exact
            path="/ExternalPaymentWindow"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PaymentAllowIndex />
              </Suspense>
            }
          />
          <Route
            exact
            path="/ExternalPaymentReport/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <ExternalPaymentReport />
              </Suspense>
            }
          />
          <Route
            exact
            path="/registration-summary"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <RegistrationSummary />
              </Suspense>
            }
          />
          {/* Session Master */}

          <>
            <Route
              exact
              path={"/SessionMaster"}
              element={<Navigate replace to="/SessionMaster/Session" />}
            />

            {["/SessionMaster/Session", "/SessionMaster/Room"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <SessionMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/SessionAssignmentForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SessionAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SessionAssignmentForm/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SessionAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SessionAssignmentIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SessionAssignmentIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SessionRoomInvigilatorAssignment/Assign/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SessionRoomInvigilatorAssignment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SessionStudentAssignmentIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SessionStudentAssignmentIndex />
                </Suspense>
              }
            />
            <Route
              excat
              path="/SessionCourseAndDateMappingIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SessionCourseAndDateMappingIndex />
                </Suspense>
              }
            />
          </>

          {/* Candidatewalkin Master  */}
          <>
            <Route
              exact
              path={"/CandidatewalkinMaster"}
              element={
                <Navigate replace to="/CandidatewalkinMaster/Registration" />
              }
            />
            <Route
              path="/CandidatewalkinOfferIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CandidateWalkinOfferIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/SchedulerMaster"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SchedulerMaster />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InternalTimeTable"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternaltimeTable />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FacultyDetails"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FacultyDetails />
                </Suspense>
              }
            />

            <Route
              exact
              path="/facultydetails/StudentDetailsByClass"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDetailsByBatch />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FacultyDetails/AttendanceReport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FacultyDetailsAttendaceReport />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InternalTimeTable/AttendanceReport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternaltimeTableAttendaceReport />
                </Suspense>
              }
            />

            <Route
              path="/CandidatewalkinMaster/Registration/View/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CandidateRegistrationEditForm />
                </Suspense>
              }
            />
            {[
              "/CandidatewalkinMaster/Registration",
              "/CandidatewalkinMaster/Followup",
              "/CandidatewalkinMaster/Inactive",
              "/CandidatewalkinMaster/Counslers",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <CandidateWalkinMaster />
                  </Suspense>
                }
              />
            ))}

            {[
              "/CandidatewalkinResultMaster",
              "/CandidatewalkinResultMaster/Report",
              "/CandidatewalkinResultMaster/Import",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <CandidateWalkinResultMaster />
                  </Suspense>
                }
              />
            ))}

            <>
              <Route
                exact
                path={"/PrescholarshipapproverIndex"}
                element={<Navigate replace to="/PreGrantMaster/Approve" />}
              />
              {["/PreGrantMaster/Approve", "/PreGrantMaster/History"].map(
                (path) => (
                  <Route
                    exact
                    key={path}
                    path={path}
                    element={
                      <Suspense fallback={<OverlayLoader />}>
                        <PreGrantApproveMaster />
                      </Suspense>
                    }
                  />
                )
              )}
            </>

            <>
              <Route
                exact
                path={"/PreScholarshipVerifierIndex"}
                element={
                  <Navigate replace to="/PreGrantVerifyMaster/Approve" />
                }
              />
              {[
                "/PreGrantVerifyMaster/Approve",
                "/PreGrantVerifyMaster/History",
              ].map((path) => (
                <Route
                  exact
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<OverlayLoader />}>
                      <PreGrantVerifyMaster />
                    </Suspense>
                  }
                />
              ))}
            </>

            <Route
              exact
              path="/GrantApprover"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ScholarshipApproverIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/GrantApproverHistory"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ScholarshipApproverHistory />
                </Suspense>
              }
            />

            <Route
              exact
              path="/CandidateAdmissionForm/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CandidateAdmissionForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/RecruitmentNew/:id/:offerId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RecruitmentNew />
                </Suspense>
              }
            />
          </>
          {/* Journal Voucher  */}
          <Route
            exact
            path="/JournalVoucherForm/New"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <JournalVoucherForm />
              </Suspense>
            }
          />

          <Route
            exact
            path="/GRNIndex/new"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <GRNPage />
              </Suspense>
            }
          />
          <Route
            exact
            path="/GRNPdf/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <GrnPdf />
              </Suspense>
            }
          />

          <Route
            exact
            path="/GRNPdfNew/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <Grnpdfone />
              </Suspense>
            }
          />

          <Route
            exact
            path="/GRNIndex"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <GRNIndex />
              </Suspense>
            }
          />

          <Route
            exact
            path="/JournalVoucherUpdate/:id/:schoolId/:fcyearId"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <JournalVoucherForm />
              </Suspense>
            }
          />
          {/* InventoryMaster */}
          <>
            <Route
              exact
              path={"/InventoryMaster"}
              element={<Navigate replace to="/InventoryMaster/Stores" />}
            />
            {[
              "/InventoryMaster/Stores",
              "/InventoryMaster/Measures",
              "/InventoryMaster/Vendor",
              "/InventoryMaster/Item",
              "InventoryMaster/InStr",
              "/InventoryMaster/Assignment",
              "/InventoryMaster/Library",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <InventoryMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path={"/ItemMaster"}
              element={<Navigate replace to="/ItemMaster/GRN" />}
            />
            {[
              "/ItemMaster/StockIssue",
              "/ItemMaster/StockRegister",
              "/ItemMaster/GRN",
              "/ItemMaster/Library",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <ItemMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/ClosingstockReport/:itemName/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ClosingstockReport />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StockSubRegister/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StockSubRegister />
                </Suspense>
              }
            />

            <Route
              exact
              path="/LibraryBookIssue"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LibraryBookIssue />
                </Suspense>
              }
            />

            <Route
              exact
              path="/AccessionBookIndex/:accessionNo"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AccessionBookIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/LibraryBarCode"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LibraryBarCode />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/Stores/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StoreForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InventoryMaster/Stores/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StoreForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InventoryMaster/Measures/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MeasureForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InventoryMaster/Measures/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MeasureForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InventoryMaster/Vendor/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VendorForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InventoryMaster/Vendor/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VendorForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/VendorIndex/View/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <View />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/Item/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ItemCreation />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/Item/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ItemCreation />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/Assignment/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ItemAssignemnt />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/Assignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ItemAssignemnt />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ItemAssignmentIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ItemAssignmentIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/StoreIndent/new"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StoreIndent />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/StoreIndentIndex"
              element={<StoreIndentIndex />}
            />

            <Route
              exact
              path="/InventoryMaster/StoreIndent/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StoreIndent />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StoreIndentApproverIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StoreIndentApproverIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StoreIndentHistory"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StoreIndentHistory />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StoreIndentRequests"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StoreIndentRequests />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ApproverCreation"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApproverCreation />
                </Suspense>
              }
            />

            <Route
              exact
              path="/Approver/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApproverCreation />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ApproverIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApproverIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ApproverIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApproverIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/payReport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <PaySlip />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StockRegister"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StockRegister />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StockIssueNew"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StockIssue />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StockIssue"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StockIssueIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/RouteList"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RouteList />
                </Suspense>
              }
            />
            <Route
              exact
              path="/payReport/printpdf"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <PayReport />
                </Suspense>
              }
            />

            <Route
              exact
              path="/PayreportPdf"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <PayreportPdf />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/PaymentVoucher/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <PaymentVoucher />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InventoryMaster/:id/PaymentVoucher"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SchoolPaymentVoucher />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InventoryMaster/:id/JournalVoucher"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <JournalVoucher />
                </Suspense>
              }
            />

            <Route
              exact
              path="/DraftPaymentVoucherIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DraftPaymentVoucherIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/DraftPaymentPdfView/:voucherNo"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DraftPaymentPdfView />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ApproveDraftPayingVoucher/:voucherNo/:schoolId/:fcyearId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApproveDraftPayingVoucher />
                </Suspense>
              }
            />
          </>
          <Route
            exact
            path="/PaymentVoucherPdf/:voucherNo/:schoolId/:fcyearId"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PaymentVoucherPdf />
              </Suspense>
            }
          />
          <Route
            exact
            path="/PaymentJournalVoucherPdf/:id/:schoolId/:fcyearId"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PaymentJournalVoucherPdf />
              </Suspense>
            }
          />
          <Route
            exact
            path="/JournalVoucherPdf/:voucherNo"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <JournalVoucherPdf />
              </Suspense>
            }
          />
          <Route
            exact
            path="/PaymentVoucherIndex"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PaymentVoucherIndex />
              </Suspense>
            }
          />

          <Route
            exact
            path="/CreatedStock"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CreatedStock />
              </Suspense>
            }
          />
          <Route
            exact
            path="/CreatedGRN"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <CreatedGRN />
              </Suspense>
            }
          />
          {/* Payment Master */}
          <>
            <Route
              exact
              path={"/PaymentMaster"}
              element={<Navigate replace to="/PaymentMaster/Payment" />}
            />
            {[
              "/PaymentMaster/Payment",
              "/PaymentMaster/Feereceipt",
              "/PaymentMaster/Journal",
              "/PaymentMaster/Contra",
              "/PaymentMaster/Salary",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <PaymentMaster />
                  </Suspense>
                }
              />
            ))}

            {/* <Route
              exact
              path="/PaymentMaster/Journal"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <PaymentJournal />
                </Suspense>
              }
            /> */}

            <Route
              exact
              path="/PaymentMaster/PaymentVoucher/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DraftpaymentVoucher />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ReportMaster/ContraIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ContraIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ReportMaster/CreateContra/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CreateContra />
                </Suspense>
              }
            />
            <Route
              exact
              path="/PaymentContraVoucherPdf/:id/:yearId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <PaymentContraVoucherPdf />
                </Suspense>
              }
            />
          </>

          <>
            <Route
              exact
              path={"/Draftvouchers"}
              element={<Navigate replace to="/Draftvouchers/Payment" />}
            />
            {[
              "/Draftvouchers/Payment",
              "/Draftvouchers/Journal",
              "/Draftvouchers/Contra",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <DraftVouchers />
                  </Suspense>
                }
              />
            ))}
          </>
          {/* Transcript Master */}
          <>
            <Route
              exact
              path={"/TranscriptMaster"}
              element={<Navigate replace to="/TranscriptMaster/Transcripts" />}
            />
            {[
              "/TranscriptMaster/Transcripts",
              "/TranscriptMaster/Assignments",
              "/TranscriptMaster/Universitys",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <TranscriptMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/TranscriptMaster/Transcript/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TranscriptForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TranscriptMaster/Transcript/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TranscriptForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TranscriptMaster/TranscriptAssignment/Assign"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TranscriptAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TranscriptMaster/University/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <UniversityForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TranscriptMaster/University/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <UniversityForm />
                </Suspense>
              }
            />
          </>
          {/*Transport Master */}
          <>
            <Route
              exact
              path={"/TransportMaster"}
              element={<Navigate replace to="/TransportMaster/Assignment" />}
            />
            {["/TransportMaster/Assignment"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <TransportMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/TransportMaster/TransportAssignment/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TransportAssignment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TransportMaster/TransportAssignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TransportAssignment />
                </Suspense>
              }
            />
          </>
          {/* Infrastructure Master*/}
          <>
            <Route
              exact
              path={"/InfrastructureMaster"}
              element={<Navigate replace to="/InfrastructureMaster/Facility" />}
            />
            {[
              "/InfrastructureMaster/Facility",
              "/InfrastructureMaster/Block",
              "/InfrastructureMaster/Floor",
              "/InfrastructureMaster/Rooms",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <InfrastructureMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/InfrastructureMaster/Facility/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FacilityForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InfrastructureMaster/Facility/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FacilityForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/InfrastructureMaster/Block/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BlockForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InfrastructureMaster/Block/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BlockForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InfrastructureMaster/Rooms/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RoomForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InfrastructureMaster/Rooms/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RoomForm />
                </Suspense>
              }
            />
          </>
          {/*Feetemplate Master */}
          <>
            <Route
              exact
              path="/FeetemplateMaster"
              element={<Navigate replace to="/FeetemplateMaster/Feetemplate" />}
            />
            {["/FeetemplateMaster/Feetemplate", "FeetemplateMaster/Route"].map(
              (path) => (
                <Route
                  exact
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<OverlayLoader />}>
                      <FeetemplateMaster />
                    </Suspense>
                  }
                />
              )
            )}

            <Route
              exact
              path="/FeetemplateMaster/Feetemplate/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeeTemplate />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeetemplateMaster/Feetemplate/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeeTemplate />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeetemplateSubamount/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeetemplateSubamount />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeetemplateMaster/EditFeetemplateSubAmount/:id/1"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeetemplateSubamount />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeetemplateMaster/EditFeetemplateSubAmount/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeetemplateSubamount />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeetemplateSubAmountHistory/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeetemplateSubAmountHistory />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeetemplateAttachmentView/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeetemplateAttachmentView />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ViewFeetemplateSubAmount/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ViewFeetemplateSubAmount />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ViewFeetemplateSubAmount/:id/1"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ViewFeetemplateSubAmount />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeetemplateApproval/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeetemplateApproval />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeetemplateApprovalIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeetemplateApprovalIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/Applicationfee"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApplicationFee />
                </Suspense>
              }
            />
            <Route
              exact
              path="/Applicationfee/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApplicationFee />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ApplicationFeeIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApplicationFeeIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/FeetemplateMaster/VehicleRoute/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <VehicleRouteForm />
                </Suspense>
              }
            />
          </>
          {/*Course  */}
          <>
            <Route
              exact
              path="/CourseMaster"
              element={<Navigate replace to="/CourseMaster/Course" />}
            />
            {[
              "/CourseMaster/Course",
              "/CourseMaster/Category",
              "/CourseMaster/Type",
              "/CourseMaster/Pattern",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <CourseMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/CourseForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseAssignment"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseAssignment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseAssignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseAssignment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseForm/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseTypeForm/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseTypeForm/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseCategoryForm/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseCategoryForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseCategoryForm/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseCategoryForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseMaster/Student/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseStudentAssignment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseMaster/Student/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseStudentAssignment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CoursePatternForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CoursePatternForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CoursePatternForm/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CoursePatternForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/CoursePatternIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CoursePatternIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseassignmentIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseassignmentIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/Courseassignmentstudentindex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <Courseassignmentstudentindex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/CourseStudentAssignmentIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseStudentAssignmentIndex />
                </Suspense>
              }
            />
          </>
          {/*Course Subjective */}
          <>
            <Route
              exact
              path={"/CourseSubjectiveMaster"}
              element={
                <Navigate replace to="/CourseSubjectiveMaster/Objective" />
              }
            />
            {[
              "/CourseSubjectiveMaster/Objective",
              "/CourseSubjectiveMaster/Outcome",
              "/CourseSubjectiveMaster/Syllabus",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <CourseSubjectiveMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/CourseSubjectiveMaster/CourseObjective/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseObjectiveForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseSubjectiveMaster/CourseObjective/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseObjectiveForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/EditJournalDraftVoucher/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <JournalVoucherEdit />
                </Suspense>
              }
            />

            <Route
              exact
              path="/CourseSubjectiveMaster/CourseOutcome/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseOutcomeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseSubjectiveMaster/CourseOutcome/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseOutcomeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseSubjectiveMaster/Syllabus/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SyllabusForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CourseSubjectiveMaster/Syllabus/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SyllabusForm />
                </Suspense>
              }
            />
          </>

          {/*Catering Master */}
          <>
            <Route
              exact
              path={"/CateringMaster"}
              element={
                <Navigate replace to="/CateringMaster/RefreshmentTypeIndex" />
              }
            />
            {[
              "/CateringMaster/RefreshmentTypeIndex",
              "/CateringMaster/MessAssignmentIndex",
              "/CateringMaster/InstituteMealIndex",
              "/CateringMaster/RefreshmentCalenderView",
              "/CateringMaster/RefreshmentRequestIndex",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <AssignmentDetailsMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path={"/RefreshmentDetails"}
              element={
                <Navigate
                  replace
                  to="/RefreshmentDetails/RefreshmentApproverIndex"
                />
              }
            />
            {[
              "/RefreshmentDetails/RefreshmentApproverIndex",
              "/RefreshmentDetails/RefreshmentMailBox",
              "/RefreshmentDetails/RefreshmentRequestReport",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <RefreshmentDetailsMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/CateringMaster/RefreshmentTypeIndex/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RefreshmentTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/RefreshmentDetails/RefreshmentTypeIndex/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CreateRefreshmentRequest />
                </Suspense>
              }
            />
            <Route
              exact
              path="/RefreshmentDetails/RefreshmentTypeIndex/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CreateRefreshmentRequest />
                </Suspense>
              }
            />
            <Route
              exact
              path="/RefreshmentDetails/RefreshmentTypeIndex/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CreateRefreshmentRequest />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CateringMaster/RefreshmentTypeIndex/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RefreshmentTypeForm />
                </Suspense>
              }
            />
            {/* <Route
              exact
              path="/CateringMaster/RefreshmentTypeIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CreateRefreshmentIndex />
                </Suspense>
              }
            /> */}
            <Route
              exact
              path="/CateringMaster/MessAssign/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MessAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CateringMaster/MessAssign/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MessAssignmentForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/CateringMaster/MealAssign/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MealAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CateringMaster/MealAssign/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MealAssignmentForm />
                </Suspense>
              }
            />

            {/* <Route
              exact
              path="/RefreshmentRequest"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RefreshmentRequestIndex />
                </Suspense>
              }
            /> */}

            <Route
              exact
              path="/CateringMaster/RefreshmentRequestIndex/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RefreshmentRequestForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/RefreshmentRequest/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RefreshmentRequestForm />
                </Suspense>
              }
            />
            {/* 
<Route
              exact
              path="/RefreshmentRequestReport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RefreshmentRequestReport />
                </Suspense>
              }
            /> */}
            <Route
              exact
              path={"/RefreshmentMaster"}
              element={
                <Navigate
                  replace
                  to="/RefreshmentMaster/RefreshmentRequestIndex"
                />
              }
            />
            {[
              "/RefreshmentMaster/RefreshmentRequestIndex",
              "/RefreshmentMaster/RefreshmentRequestReport",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <RefreshmentMaster />
                  </Suspense>
                }
              />
            ))}
          </>

          {/*Category Type Master */}
          <>
            <Route
              exact
              path={"/CategoryTypeMaster"}
              element={
                <Navigate replace to="/CategoryTypeMaster/CategoryTypes" />
              }
            />
            {[
              "/CategoryTypeMaster/CategoryTypes",
              "/CategoryTypeMaster/CategoryDetail",
              "/CategoryTypeMaster/CommencementTypes",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <CategoryTypeMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/CategoryTypeMaster/CategoryTypes/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CategoryTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CategoryTypeMaster/CategoryTypes/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CategoryTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CategoryTypeMaster/CategoryDetail/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CategoryDetailsForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CategoryTypeMaster/CategoryDetail/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CategoryDetailsForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CategoryTypeMaster/CommencementType/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CommencementTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/CategoryTypeMaster/CommencementType/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CommencementTypeForm />
                </Suspense>
              }
            />
          </>
          {/*HolidayCalenderMaster */}
          <>
            <Route
              exact
              path={"/HolidayCalenderMaster"}
              element={
                <Navigate
                  replace
                  to="/HolidayCalenderMaster/HolidayCalenders"
                />
              }
            />
            {["/HolidayCalenderMaster/HolidayCalenders"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <HolidayCalenderMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/HolidayCalenderMaster/HolidayCalenders/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HolidayCalenderForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HolidayCalenderMaster/HolidayCalenders/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HolidayCalenderForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HolidayCalenderMaster/DeAssignDepartments/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DeAssignDepartment />
                </Suspense>
              }
            />
          </>
          {/* Leave Master */}
          <>
            <Route
              exact
              path={"/LeaveMaster"}
              element={<Navigate replace to="/LeaveMaster/LeaveType" />}
            />
            {[
              "/LeaveMaster/LeaveType",
              "/LeaveMaster/LeavePattern",
              "/LeaveMaster/ViewReport",
              "/LeaveMaster/Copy",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <LeaveMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/LeaveMaster/LeaveTypes/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeaveTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveMaster/LeaveTypes/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeaveTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveTypes/AttachmentView/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ViewLeavePDF />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveApplyForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeaveApplyForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/InitiateLeave"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InitialLeave />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveApplyIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeaveApplyIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveApproverIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeaveApproverIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveApprovedHistoryIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeaveApprovedHistoryIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveHistory"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeaveApplyAdminIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveDetails/:userId/:leaveId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DeatilsByLeaveType />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeaveSummary"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeaveSummary />
                </Suspense>
              }
            />
            '
          </>

          {/*Leave Pattern Master */}
          <>
            <Route
              exact
              path="/LeavePatternMaster/LeavePatterns/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeavePatternForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeavePatternMaster/LeavePatterns/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LeavePatternForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/LeavePatternMaster/ViewReports/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ViewReport />
                </Suspense>
              }
            />
          </>

          {/* Restrict Window */}
          <Route
            exact
            path={"/RestrictWindow"}
            element={<Navigate replace to="/RestrictWindow/paysliplock" />}
          />
          {["/RestrictWindow/paysliplock", "/RestrictWindow/salary"].map(
            (path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <RestrictWindowMaster />
                  </Suspense>
                }
              />
            )
          )}

          <Route
            exact
            path="/restrictwindow/paysliplock/create"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PaysliplockCreate />
              </Suspense>
            }
          />
          <Route
            exact
            path="/restrictwindow/paysliplock/edit/:id"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <PaysliplockEdit />
              </Suspense>
            }
          />

          {/* Salary Increment */}
          <>
            <Route
              exact
              path="/SalaryIncrementEmpList"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryIncrementInitIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/SalaryBudgetCreate"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryBudgetCreate />
                </Suspense>
              }
            />

            <Route
              exact
              path="/BudgetCreateCsv"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BudgetCreateCsv />
                </Suspense>
              }
            />

            <Route
              exact
              path="/IncrementIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <IncrementIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/IncrementFinalizedList"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <IncrementFinalizedList />
                </Suspense>
              }
            />

            <Route
              exact
              path="/BudgetCreatedIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BudgetIncrementIndex />
                </Suspense>
              }
            />
          </>
          {/* Hostel Master */}
          <>
            <Route
              exact
              path={"/HostelMaster"}
              element={<Navigate replace to="/HostelMaster/Blocks" />}
            />
            {[
              "/HostelMaster/Blocks",
              "/HostelMaster/Floors",
              "/HostelMaster/Wardens",
              "/HostelMaster/RoomTypes",
              "/HostelMaster/HostelRooms",
              "/HostelMaster/StandardAccessories",
              "/HostelMaster/GridView",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <HostelMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/HostelMaster/Blocks/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HostelBlockForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelMaster/Blocks/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HostelBlockForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelMaster/Wardens/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DoctorWardenForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelMaster/RoomTypes/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RoomTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelMaster/RoomTypes/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <RoomTypeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelMaster/StandardAccessories/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StandardAccessoriesForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelMaster/StandardAccessories/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StandardAccessoriesForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelMaster/HostelRooms/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HostelRoomForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelMaster/HostelRooms/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HostelRoomForm />
                </Suspense>
              }
            />
          </>
          {/* Hostel Fee Master */}
          <>
            <Route
              exact
              path={"/HostelFeeMaster"}
              element={<Navigate replace to="/HostelFeeMaster/HostelFees" />}
            />
            {["/HostelFeeMaster/HostelFees"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <HostelFeeMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/HostelFeeMaster/HostelFee/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HostelFeeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/HostelFeeMaster/HostelFee/View/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ViewFeeTemplate />
                </Suspense>
              }
            />
          </>

          {/*Proctor Student Master */}
          <>
            <Route
              exact
              path={"/ProctorStudentMaster"}
              element={<Navigate replace to="/ProctorStudentMaster/Proctor" />}
            />
            {[
              "/ProctorStudentMaster/Proctor",
              "/ProctorStudentMaster/History",
              "/ProctorStudentMaster/Meeting",
              "/ProctorStudentMaster/Report",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <ProctorStudentMaster />
                  </Suspense>
                }
              />
            ))}
          </>

          {/*Mentor Master */}
          <>
            <Route
              exact
              path={"/ProctorMaster"}
              element={<Navigate replace to="/ProctorMaster/Proctor" />}
            />
            {[
              "/ProctorMaster/Proctor",
              "/ProctorMaster/History",
              "/ProctorMaster/Meeting",
              "/ProctorMaster/Report",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <MentorMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/MentorMaster/Mentor/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorheadForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/MentorMaster/Mentor/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorheadForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ProctorMaster/Proctor/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorStudentAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/MentorAssignmentIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorStudentAssignmentIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/Proctorstudenthistory"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorStudentHistory />
                </Suspense>
              }
            />

            {/* <Route ex */}

            <Route
              exact
              path="/ProctorMeeting"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorMeeting />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ProctorStudentMeeting"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorStudentMeeting />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ProctorStudentsMeeting"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorStudentsMeeting />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ProctorStudentMeetingIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorStudentMeetingIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/MentorIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProctorheadIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StudentDetailsMaster/StudentsDetailsView/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDetailsView />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StudentDetailsView/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDetailsView />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ChangeOfCourse/:studentId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ChangeOfCourse />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ChangeOfCourseIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ChangeOfCourseIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ChangeOfCourseAttachment/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ChangeOfCourseAttachment />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ApproverChangeofcourse/:studentId/:oldStudentId/:oldSpecializationId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApproveChangeofcourse />
                </Suspense>
              }
            />

            <Route
              exact
              path="/FeeTransfer/:auid"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeeTransfer />
                </Suspense>
              }
            />
          </>
          {/*Student Master */}
          <>
            <Route
              exact
              path="/ExtraRemuneration"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ExtraRemuneration />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ExtraRemunerationIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ExtraRemunerationIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentMaster/LessonplanForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LessonplanForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentMaster/StudentAttendance"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentAttendace />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentMaster/StudentAttendanceReport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentAttendaceReport />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentAssessment"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentAssessment />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentAssessmentPdf/:courseName/:acYearId/:programAssignmentId/:programId/:specializationId/:sectionId/:yearsemId/:courseId/:courseAssignmentId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentAssessmentPdf />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentMaster/ReferencebookForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <Referencebookform />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentMaster/ReferencebookForm/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <Referencebookform />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentMaster/LessonplanIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LessonplanIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentMaster/ReferencebookIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ReferencebookIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/Studentmaster/Dollartosom/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DollartoSomConversion />
                </Suspense>
              }
            />
            <Route
              exact
              path="/Studentmaster/Dollartosom/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DollartoSomConversion />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StudentLibraryDetails"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentLibraryDetails />
                </Suspense>
              }
            />

            <Route
              exact
              path="/LibraryDetails"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LibraryDetails />
                </Suspense>
              }
            />

            <Route
              exact
              path="/LibraryFinePayment/:fine"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <LibraryFinePayment />
                </Suspense>
              }
            />

            <Route
              exact
              path="/DollartosomIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DollartosomIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentFeeReceipt/:receiptType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentFeeReceipt />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeeReceipt"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeeReceipt />
                </Suspense>
              }
            />

            <Route
              exact
              path="/FeeReceiptIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeeReceiptIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/FeeReceiptDetails/:auid/:studentId/:feeReceipt/:financialYearId/:transactionType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeeReceiptDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path="/stdFeeReceipt/:auid/:studentId/:feeReceipt/:financialYearId/:transactionType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentFeeReceiptDetailsPDF />
                </Suspense>
              }
            />
            <Route
              exact
              path="/FeeReceiptDetailsPDF/:auid/:studentId/:feeReceipt/:financialYearId/:transactionType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FeeReceiptDetailsPDF />
                </Suspense>
              }
            />

            <Route
              exact
              path="/EntranceExamDetails/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EntranceExamDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path="/EntranceExamDetails/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EntranceExamDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path="/EntranceExamDetails/EntranceExamDetailsIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EntranceExamDetailsIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BulkFeeReceiptForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BulkFeeReceipt />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BulkFeeReceipt/:receiptType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BulkFeeReceipt />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BulkFeeReceiptView/:feeReceiptId/:transactionType/:financialYearId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BulkFeeReceiptView />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BulkFeeReceiptView/:studentId/:feeReceiptId/:transactionType/:financialYearId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BulkFeeReceiptView />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BulkFeeReceiptPdf/:feeReceiptId/:transactionType/:financialYearId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BulkFeeReceiptPdf />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BulkFeeReceiptPdf/:studentId/:feeReceiptId/:transactionType/:financialYearId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BulkFeeReceiptPdf />
                </Suspense>
              }
            />

            <Route
              exact
              path="/CancelFeeReceipt"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CancelFeeReceipt />
                </Suspense>
              }
            />

            <Route
              exact
              path="/CancelFeeReceiptIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CancelFeeReceiptIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/HallticketDownloadedIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <HallticketDownloadedIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentDetailsMaster/StudentsDetails/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDetailsView />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentDetailsViewAttendance/:studentId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDetailsViewAttendance />
                </Suspense>
              }
            />
            <Route
              exact
              path="/biotransImport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ImportBioTrans />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StudentPaymentMaster"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentPaymentMaster />
                </Suspense>
              }
            />

            {[
              "/StudentPaymentMaster/Clickme",
              "/StudentPaymentMaster/Payme",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <StudentPaymentMaster />
                  </Suspense>
                }
              />
            ))}
          </>
          {/*Section Master*/}
          <>
            <Route
              exact
              path={"/SectionMaster"}
              element={<Navigate replace to="/SectionMaster/Sections" />}
            />
            {[
              "/SectionMaster/Sections",
              "/SectionMaster/Batches",
              "/SectionMaster/CourseAssign",
              "/SectionMaster/IntervalTypes",
              "/SectionMaster/Internal",
              "/SectionMaster/Timetable/Batch",
              "/SectionMaster/Timeslot",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <SectionMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/SectionMaster/Section/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SectionForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/Section/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SectionForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/Batch/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BatchForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/Batch/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BatchForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimetableMaster/SectionAssignmentForm/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SectionAssignmentForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimetableMaster/SectionAssignmentUpdate/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SectionAssignmentForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/SectionMaster/Promote/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentPromote />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/TimeSlots/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeSlotsForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/TimeSlots/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeSlotsForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/SectionMaster/intervaltype/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeIntervalTypesForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/intervaltype/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeIntervalTypesForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/Internal/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternalCreationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/Internal/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InternalCreationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/timeslots/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeSlotsForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SectionMaster/timeslots/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeSlotsForm />
                </Suspense>
              }
            />
          </>
          {/*Exit Form Master */}
          <>
            <Route
              exact
              path={"/ExitFormMaster"}
              element={<Navigate replace to="/ExitFormMaster/ExitQuestions" />}
            />
            {["/ExitFormMaster/ExitQuestions", "/ExitFormMaster/ExitForms"].map(
              (path) => (
                <Route
                  exact
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<OverlayLoader />}>
                      <ExitFormMaster />
                    </Suspense>
                  }
                />
              )
            )}
            <Route />
            <Route
              exact
              path="/ExitFormMaster/exitquestion/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ExitQuestionsForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ExitFormMaster/exitquestion/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ExitQuestionsForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ExitFormMaster/ExitForm/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ExitForm />
                </Suspense>
              }
            />
          </>
          <Route
            exact
            path="/employee-feedback"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <EmployeeFeedbackIndex />
              </Suspense>
            }
          />
          <Route
            exact
            path="/employee-feedback/report/:empId/:acYearId"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <EmployeeFeedbackReport />
              </Suspense>
            }
          />
          <Route
            exact
            path="/StudentFeedbackMaster/allow-student-feedback"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <AllowStudentFeedback />
              </Suspense>
            }
          />
          <Route
            exact
            path="/submit-student-feedback"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <SubmitFeedbackSelect />
              </Suspense>
            }
          />
          <Route
            exact
            path="/submit-student-feedback/:studentId/:empId/:subjectId"
            element={
              <Suspense fallback={<OverlayLoader />}>
                <SubmitFeedback />
              </Suspense>
            }
          />
          {/*Student Feedback Master */}
          <>
            <Route
              exact
              path={"/StudentFeedbackMaster"}
              element={
                <Navigate replace to="/StudentFeedbackMaster/questions" />
              }
            />
            {[
              "/StudentFeedbackMaster/questions",
              "/StudentFeedbackMaster/feedbackwindow",
              "/StudentFeedbackMaster/freezepercentage",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <StudentFeedbackMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/StudentFeedbackMaster/feedbackwindow/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentFedbackWindow />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentFeedbackMaster/feedbackwindow/update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentFeedbackWindowUpdate />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentFeedbackMaster/freezePercentage/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentFeedbackFreezeCreate />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentFeedbackMaster/freezePercentage/update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentFeddbackFreezeUpdate />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentFeedbackMaster/Feedback/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentFeedbackForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentFeedbackMaster/Feedback/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentFeedbackForm />
                </Suspense>
              }
            />
          </>
          {/* Time Table Master */}
          <>
            <Route
              exact
              path={"/TimeTableMaster"}
              element={<Navigate replace to="/TimeTableMaster/Timetable" />}
            />
            {[
              "TimeTableMaster/Timetable",
              "/TimeTableMaster/Course",
              "/TimeTableMaster/Section",
              "/TimeTableMaster/Batchassignment",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <TimeTableMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/TimeTableMaster/sectionassignmentform/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SectionAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TimeTableMaster/SectionAssignmentUpdate/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SectionAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TimeTableMaster/Timetable/Section/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimetableForSectionForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TimeTableMaster/Timetable/Batch/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimetableForBatchForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TimeTableMaster/timeslots/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeSlotsForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TimeTableMaster/batchassignment/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BatchAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TimeTableMaster/batchassignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BatchAssignmentForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimeTableMaster/CourseAssignment/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseAssignmentForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/TimeTableMaster/CourseAssignment/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CourseAssignmentForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimeTableMaster/TimeTableView"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeTabeleView />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimeTableViewDateWise/:acYearId/:schoolId/:programId/:programSpeId/:yearsemId/:sectionId/:date/:programType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeTableViewDateWise />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimeTableDateWisePDF/:acYearId/:schoolId/:programId/:programSpeId/:yearsemId/:sectionId/:date/:programType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeTableDateWisePDF />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimeTableViewForCourse/:acYearId/:schoolId/:programId/:programSpeId/:yearsemId/:sectionId/:date/:courseId/:programType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeTableViewForCourse />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimeTableViewWeekWise/:acYearId/:schoolId/:programId/:programSpeId/:yearsemId/:sectionId/:date/:programType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeTableViewWeekWise />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimeTableFacultyViewPDF/:acYearId/:schoolId/:programId/:programSpeId/:yearsemId/:sectionId/:date/:courseId/:programType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeTableFacultyViewPDF />
                </Suspense>
              }
            />

            <Route
              exact
              path="/TimeTableViewWeekWisePdf/:acYearId/:schoolId/:programId/:programSpeId/:yearsemId/:sectionId/:date/:programType"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TimeTableViewWeekWisePdf />
                </Suspense>
              }
            />
          </>
          {/*  StudentTranscriptMaster*/}
          <>
            <Route
              exact
              path={"/StudentTranscriptMaster"}
              element={
                <Navigate
                  replace
                  to="/StudentTranscriptMaster/StudentTranscript"
                />
              }
            />

            {["/StudentTranscriptMaster/StudentTranscript"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <StudentTranscriptMaster />
                  </Suspense>
                }
              />
            ))}

            <Route
              exact
              path="/StudentTranscriptMaster/DocumentCollection/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentTranscriptForm />
                </Suspense>
              }
            />
          </>
          {/* Student Details Master */}
          <>
            <Route
              exact
              path={"/StudentDetailsMaster"}
              element={
                <Navigate replace to="/StudentDetailsMaster/StudentsDetails" />
              }
            />
            {[
              "/StudentDetailsMaster/StudentsDetails",
              "/StudentDetailsMaster/InactiveStudents",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <StudentDetailsMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/StudentDetailsMaster/inactivestudents"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <InactiveStudentsIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/stdduereport"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDueDetailsMaster />
                </Suspense>
              }
            />

            <Route
              exact
              path="/DueFollowHistory/:auid/:due"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DueFollowHistory />
                </Suspense>
              }
            />

            <Route
              exact
              path="/stdduereport/programme/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDueByProgramme />
                </Suspense>
              }
            />

            <Route
              exact
              path="/stdmarks"
              element={<Navigate replace to="/stdmarks/exam" />}
            />
            {["/stdmarks/exam", "/stdmarks/report"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <StudentMarksMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/stdmarks/exam"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentMarks />
                </Suspense>
              }
            />

            <Route
              exact
              path="/stdmarks/report"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentMarksIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/stdmarks"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentMarksMaster />
                </Suspense>
              }
            />
            <Route
              exact
              path="/empbiometric"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeBioMetricData />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StudentDetailsMaster/ProvisionCertificate/View/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ProvisionCertificate />
                </Suspense>
              }
            />
            <Route
              exact
              path="/DocumentCollection/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <DocumentCollectionForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/canceladmissioninitiate/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CancelAdmissionInitiate />
                </Suspense>
              }
            />

            <Route
              exact
              path="/canceladmissiondetails"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CancelAdmissionDetails />
                </Suspense>
              }
            />

            <Route
              exact
              path="/canceladmissionapprover"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CancelAdmissionApprover />
                </Suspense>
              }
            />

            <Route
              exact
              path="/approvalofcancelAdmission"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ApprovalCancelAdmission />
                </Suspense>
              }
            />

            <Route
              exact
              path="/cancelStudentDeatils"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <CancelStudentDeatils />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StudentTranscriptApplication/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentTranscriptApplication />
                </Suspense>
              }
            />

            <Route
              exact
              path="/GrantApplicationPrint/:studentId/:scholarshipId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <GrantPrintApplication />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentDuePayment"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDuePaymentForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StudentDuePayment/:orderId"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDuePaymentForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/StudentDuePayment/:orderId/:type"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentDuePaymentForm />
                </Suspense>
              }
            />
          </>
          {/*Bank Master */}
          <>
            <Route
              exact
              path={"/BankMaster"}
              element={<Navigate replace to="/BankMaster/Import" />}
            />
            {["/BankMaster/Import", "/BankMaster/Bank"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <BankMaster />
                  </Suspense>
                }
              />
            ))}
            <Route />
            <Route
              exact
              path="/BankMaster/Bank/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BankForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BankMaster/Bank/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BankForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/BankIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BankIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/BankMaster/BankImport/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BankImport />
                </Suspense>
              }
            />
            <Route
              exact
              path="/BankClearedHistory"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <BankClearedHistory />
                </Suspense>
              }
            />
          </>
          {/*Student Intake */}
          <Route
            exact
            path={"/StudentIntakeMaster"}
            element={
              <Navigate replace to="/StudentIntakeMaster/Studentintake" />
            }
          />
          {[
            "/StudentIntakeMaster/Studentintake",
            "StudentIntakeMaster/Summary",
            "StudentIntakeMaster/Grid",
          ].map((path) => (
            <Route
              exact
              key={path}
              path={path}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentIntakeMaster />
                </Suspense>
              }
            />
          ))}
          <>
            <Route
              exact
              path="/StudentIntakeForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentIntakeForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/StudentIntakeSelection"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentIntakeSelection />
                </Suspense>
              }
            />

            <Route
              exact
              path="/Summary"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentIntakeSummary />
                </Suspense>
              }
            />
          </>
          {/*Event Master */}
          <>
            <Route
              exact
              path="/daily-planner"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <TaskList />
                </Suspense>
              }
            />
            <Route
              exact
              path="/daily-planner/create"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EventForm />
                </Suspense>
              }
            />
            <Route
              exact
              path={"/EventMaster"}
              element={<Navigate replace to="/EventMaster/Events" />}
            />
            {["/EventMaster/Events"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <EventMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/EventMaster/Event/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EventCreationForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/EventMaster/Event/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EventCreationForm />
                </Suspense>
              }
            />
          </>
          {/*Academic Section Master */}
          <>
            <Route
              exact
              path={"/AcademicSectionMaster"}
              element={
                <Navigate
                  replace
                  to="/AcademicSectionMaster/ClassCommencement"
                />
              }
            />
            {["/AcademicSectionMaster/ClassCommencement"].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <AcademicSectionMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/AcademicSectionMaster/commencement/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ClassCommencementForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/AcademicSectionMaster/commencement/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ClassCommencementForm />
                </Suspense>
              }
            />
          </>
          {/*Report Master */}
          <>
            <Route
              exact
              path="/ReportMaster"
              element={<Navigate replace to="/ReportMaster/Reporting" />}
            />
            {[
              "/ReportMaster/Reporting",
              "/ReportMaster/Eligible",
              "/ReportMaster/Promote",
              "/ReportMaster/History",
            ].map((path) => (
              <Route
                exact
                key={path}
                path={path}
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    <ReportMaster />
                  </Suspense>
                }
              />
            ))}
            <Route
              exact
              path="/ReportMaster/Report"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ReportForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ReportMaster/Report/:schoolId/:programId/:acYearId/:yearsemId/:currentYearSem"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <ReportIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ReportMaster/Eligible"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentEligibleForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ReportMaster/Eligible/:schoolId/:programId/:yearsemId/:currentYearSem"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentEligibleIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ReportMaster/Promote"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentPromoteForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ReportMaster/Promote/:schoolId/:programId/:yearsemId/:currentYearSem"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentPromoteIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ReportMaster/History"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentHistory />
                </Suspense>
              }
            />

            <Route
              exact
              path="/ReportMaster/History/:schoolId/:programId/:yearsemId/:currentYearSem"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <StudentHistoryIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/QuestionPaperUpload"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <QuestionPaperUpload />
                </Suspense>
              }
            />

            <Route
              exact
              path="/QuestionPaperIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <QuestionPaperIndex />
                </Suspense>
              }
            />

            {/* Time Table Reports  */}
            <Route
              exact
              path="/FacultyWorkload"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FacultyWorkload />
                </Suspense>
              }
            />

            <Route
              exact
              path="/FacultyWorkloadDaywise/:empId/:year/:month/:type"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <FacultyWorkloadDaywise />
                </Suspense>
              }
            />

            {/* Employee Master  */}
            <Route
              exact
              path="/EmpManualAttendance"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmpManualAttendance />
                </Suspense>
              }
            />
            <Route
              exact
              path="/schedulertrigger"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmpAttendanceTrigger />
                </Suspense>
              }
            />
            {/* <Route
              exact
              path="/Empattendancetrigger"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmpAttendanceTrigger />
                </Suspense>
              }
            /> */}
            <Route
              exact
              path="/Attendancesheet"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmpAttendanceFilterForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryLockForm/New"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryLockForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryLockForm/Update/:id"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryLockForm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/SalaryLockIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <SalaryLockIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/EmployeeResignationIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmployeeResignationIndex />
                </Suspense>
              }
            />
            <Route
              exact
              path="/EmpResignationForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <EmpResignationForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/AdvanceDeductionForm"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AdvanceDeductionForm />
                </Suspense>
              }
            />

            <Route
              exact
              path="/AdvanceDeductionIndex"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <AdvanceDeductionIndex />
                </Suspense>
              }
            />

            <Route
              exact
              path="/PayslipReportPdf"
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <PayslipReportPdf />
                </Suspense>
              }
            />
          </>
        </Route>
        <Route
          exact
          path="/CandidateAttachment/:id/:type"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateAttachmentView />
            </Suspense>
          }
        />
        <Route
          exact
          path="/SalaryBreakupPrint/:id/:offerId"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <SalaryBreakupPrint />
            </Suspense>
          }
        />
        <Route
          exact
          path="/OfferLetterPrint/:id/:offerId"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <OfferLetterPrint />
            </Suspense>
          }
        />
        <Route
          exact
          path="/OfferAccepted/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <OfferAccepted />
            </Suspense>
          }
        />
        <Route
          exact
          path="/StudentDocumentCollectionPdf/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <StudentDocumentCollectionPdf />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateOfferLetterPdf/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateOfferLetterPdf />
            </Suspense>
          }
        />
        {/* Candidate Registration */}
        <Route
          exact
          path="/CandidateRegister"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateRegistrationForm />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateApplication"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateApplication />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateApplication/:id/:orderId"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateApplication />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateApplication/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateApplication />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateApplication/:id/:orderId/:type"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateApplication />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateDocumentsView/:path"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateDocumentsView />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateApplicationPdf/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateApplicationPdf />
            </Suspense>
          }
        />
        <Route
          exact
          path="/HallticketPdf/:referenceNo"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <HallticketPdf />
            </Suspense>
          }
        />
        <Route
          exact
          path="/HallTicketPdfRussian/:referenceNo"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <HallTicketPdfRussian />
            </Suspense>
          }
        />
        <Route
          exact
          path="/HallTicketPdfUzbek/:referenceNo"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <HallTicketPdfUzbek />
            </Suspense>
          }
        />
        <Route
          exact
          path="/DownloadHallTicket"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <DownloadHallTicket />
            </Suspense>
          }
        />

        <Route
          exact
          path="/CandidateApplicationFormView/:applicationNo"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateApplicationFormView />
            </Suspense>
          }
        />

        <Route
          exact
          path="/CandidateRegistrationForm"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateRegistrationStep1 />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateRegistrationForm/individual"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateRegistrationForm />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateRegistrationForm/direct"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateRegistrationDirectForm />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateRegistration"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateRegistration />
            </Suspense>
          }
        />

        <Route
          exact
          path="/CandidateRegistrationForm/:applicationNo"
          element={<CandidateRegistrationForm />}
        />

        <Route
          exact
          path="/CandidateRegistrationForm/:applicationNo/:orderId"
          element={<CandidateRegistrationForm />}
        />

        <Route
          exact
          path="/CandidateRegistrationForm/:applicationNo/:orderId/:type"
          element={<CandidateRegistrationForm />}
        />

        <Route
          exact
          path="/ProvisionCertificatePdf/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <ProvisionCertificatePdf />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateContractUploadDownload/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateContractUploadDownload />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateContractPdf/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateContractPdf />
            </Suspense>
          }
        />
        <Route
          exact
          path="/qualified-contract/:applicationNo"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateContractQualified />
            </Suspense>
          }
        />
        <Route
          exact
          path="/EmployeeContractPdf/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <EmployeeContractPdf />
            </Suspense>
          }
        />
        <Route
          exact
          path="/EmployeeDeclarationPdf/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <EmployeeDeclarationPdf />
            </Suspense>
          }
        />
        <Route
          exact
          path={"/StudentDetailsPdf/:id"}
          element={
            <Suspense fallback={<OverlayLoader />}>
              <StudentDetailsPdf />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateRegisterFoundation"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateFoundationPathForm />
            </Suspense>
          }
        />
        <Route
          exact
          path="/CandidateTransferInForm"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <CandidateTransferInForm />
            </Suspense>
          }
        />
        {/* Candidate Registration Ends  */}
        <Route
          exact
          path="/ExternalPayment/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <ExternalPaymentForm />
            </Suspense>
          }
        />
        <Route
          exact
          path="/ExternalPayment/:id/:orderId"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <ExternalPaymentForm />
            </Suspense>
          }
        />

        <Route
          exact
          path="/ExternalPayment/:id/:orderId/:type"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <ExternalPaymentForm />
            </Suspense>
          }
        />

        <Route
          exact
          path="/ExternalPaymentSuccessPrint/:id"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <ExternalPaymentSuccessPrint />
            </Suspense>
          }
        />

        <Route
          exact
          path="/ExternalPaymentSuccessPrint/:id/:type"
          element={
            <Suspense fallback={<OverlayLoader />}>
              <ExternalPaymentSuccessPrint />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
};
export default RouteConfig;
